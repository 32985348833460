<template>
    <div class="container">
        <Table 
            ref="pageTable"
            v-loading="loading"
            :tableData="tableData" 
            :tableOptions="tableOption" 
            :pageData="pageData" 
            @changeSearchOption="changeSearchOption"
            @handleCurrentChange="handleCurrentChange"
            @handleSizeChange="handleSizeChange"
            @changeColumn="changeColumnOption"
            @setSearchRefree="setSearchRefree"
            @searchHandle="searchHandle">
                <template v-slot:parkName="{data}">
                    <span>{{data.row.parkName}}{{data.row.anotherName?'('+data.row.anotherName+')':''}}</span>
                </template>
                <template slot="road">
                    <el-form-item prop="road" label="街道">
                        <el-input v-model="searchForm.road" clearable :disabled="searchForm.onlyRoad"></el-input>
                    </el-form-item>
                </template>
                <template slot="onlyRoad">
                    <el-form-item prop="onlyRoad" label="">
                        <el-checkbox 
                            v-model="searchForm.onlyRoad" 
                            label="只显示没有街道的小区" 
                            @change="setOnlyType">
                        </el-checkbox>
                    </el-form-item>
                </template>
                <template v-slot:right_scope="{row}" >
                    <el-button size="small" type="text" icon="el-icon-info" @click="getInfo(row,1)">小区详情</el-button>
                    <el-button size="small" type="text" icon="el-icon-video-camera" @click="getInfo(row,2)">小区监控</el-button>
                    <el-button size="small" type="text" icon="el-icon-tickets" @click="getInfo(row,3)" v-if="menuPro['dtlb']">{{menuPro['dtlb'].name}}</el-button>
                    <el-button size="small" type="text" icon="el-icon-document" @click="getInfo(row,4)">呼叫记录</el-button>
                    <el-button size="small" type="text" icon="el-icon-folder-opened" @click="getInfo(row,7)">导出年度维保计划</el-button>
                </template>
        </Table>
        <el-dialog
            title="小区详情"
            :visible.sync="infoVisible"
            width="600px"
            :close-on-click-modal="false">
            <ul class="info_panel">
                <li class="flex">
                    <label>小区名称</label><span>{{infoForm.name}}</span>
                    <i v-if="infoForm.anotherName&&infoForm.anotherName!=''">({{infoForm.anotherName}})</i>
                    <em class="if-box btn" @click="setVisible = true" v-if="menuPro['xqbz']">{{menuPro['xqbz'].name}}</em>
                </li>
                <li class="flex">
                    <label>所在区域</label><span>{{infoForm.area}}</span>
                </li>
                <li class="flex">
                    <label>所在街道</label><span>{{infoForm.name}}</span>
                </li>
                <li class="flex">
                    <label>小区地址</label><span>{{infoForm.addr}}</span>
                </li>
                <li class="flex">
                    <label>物业单位</label><span>{{infoForm.road}}</span>
                </li>
                <li class="flex">
                    <label>值班电话</label><span>{{infoForm.linkPhone}}</span>
                </li>
                <li class="flex">
                    <label>值班电话2</label><span>{{infoForm.linkPhone2}}</span>
                </li>
                <li class="flex">
                    <label>安保人员</label><span>{{infoForm.agName}}</span>
                </li>
                <li class="flex">
                    <label>安保电话</label><span>{{infoForm.agPhone}}</span>
                </li>
                <li class="flex">
                    <label>楼栋数</label><span>{{infoForm.totBuld}}</span>
                </li>
                <li class="flex">
                    <label>电梯数</label><span>{{infoForm.totLift}}</span>
                </li>
                <li class="flex">
                    <label>备注</label><span>{{infoForm.remark}}</span>
                </li>
            </ul>
            <el-dialog 
                width="530px"
                :visible.sync="setVisible"
                title="小区编辑"
                center
                append-to-body
                @close="refreeSetForm"
                :close-on-click-modal="false">
                <el-form v-model="infoForm">
                    <el-form-item label="小区备注">
                        <el-input v-model="infoForm.anotherName" ></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button size="small" @click="setVisible = false">取消</el-button>
                    <el-button type="primary" size="small" @click="subSetInfo">提交</el-button>
                </div>  
            </el-dialog>
        </el-dialog>
        <el-dialog
            title="电梯列表"
            :visible.sync="liftVisible"
            width="1300px"
            @close="refreeForm"
            :close-on-click-modal="false">
            <div class="top flex f_between">
                <div class="flex">
                    <span class="line_form_item if-box"><el-input v-model="searchForm1.name" placeholder="请输入内容"></el-input></span>
                    <span class="line_form_item if-box">
                        <el-checkbox v-model="searchForm1.type">只显示智慧电梯</el-checkbox>
                    </span>
                    <el-button type="primary" size="small" icon="el-icon-search" @click="searchHandle1">搜索</el-button>
                </div>
                <el-button type="primary" size="small" icon="el-icon-plus" @click="setSys('',1)">按需维保</el-button>
            </div>
            <Table 
                ref="schedule"
                :tableData="liftData" 
                :tableOptions="liftOption" 
                :pageData="pageData1"
                @changeSearchOption="changeSearchOption1"
                @selectionChange="selectionChangeHandle"
                @handleCurrentChange="handleCurrentChange1">
                    <template v-slot:parkName="{data}">
                        <span class="t_link" @click="setSys(data.row,2)">{{data.row.parkName}}</span>
                    </template>
                    <template v-slot:cityName="{data}">
                        <span>{{data.row.cityName+'/'+data.row.areaName}}</span>
                    </template>
                    <template v-slot:liftName="{data}">
                        <span>{{data.row.liftName}}<i v-if="data.row.liftAnotherName&&data.row.liftAnotherName!=''">({{data.row.liftAnotherName}})</i></span>
                    </template>
                    <template v-slot:right_scope="{row}" >
                        <el-button size="small" type="text" icon="el-icon-info" @click="setSys(row,3)">详情</el-button>
                        <el-button size="small" type="text" icon="el-icon-video-camera" @click="setSys(row,4)">监控</el-button>
                        <el-button size="small" type="text" icon="el-icon-film" @click="setSys(row,5)">录像</el-button>
                        <el-button size="small" type="text" icon="el-icon-headset" @click="setSys(row,6)">录像</el-button>
                    </template>
            </Table>
        </el-dialog>
        <el-dialog
            width="480px"
            title="开通按需维保"
            :visible.sync="needVisible"
            center
            @close="refreeForm"
            :close-on-click-modal="false">
            <el-form :model="rowForm" label-width="30%">
                <el-form-item label="维保间隔">
                    <el-select v-model="rowForm.month" placeholder="请选择时间间隔">
                        <el-option
                            v-for="item in spaceOpt"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>    
                </el-form-item>    
                <el-form-item label="备注">
                    <el-input v-model="rowForm.remark" type="textarea" :rows="4"></el-input>
                </el-form-item>    
            </el-form>  
            <div slot="footer" class="dialog-footer" v-if="formType != 3">
                <el-button size="small" @click="needVisible = false">取消</el-button>
                <el-button type="primary" size="small" @click="submit">提交</el-button>
            </div>  
        </el-dialog>
        <el-dialog
            width="1730px"
            :visible.sync="dataVisible"
            title="电梯详情"
            center
            @close="refreeForm"
            :close-on-click-modal="false">
            <el-tabs v-model="activeTab" @tab-click="handleClick">
                <el-tab-pane label="概览" name="gl">
                    <dataview :liftMain="itemInfo" v-if="showData"></dataview>
                </el-tab-pane>
                <el-tab-pane label="电梯档案" name="dtda">
                    <archives></archives>
                </el-tab-pane>
                <el-tab-pane label="动态数据" name="dtsj">
                    <syndata></syndata>
                </el-tab-pane>
                <el-tab-pane label="告警" name="gj">
                    <alarm></alarm>
                </el-tab-pane>
                <el-tab-pane label="工单" name="gd">
                    <alarm></alarm>
                </el-tab-pane>
                <el-tab-pane label="呼叫历史" name="hjls">
                    <callhistory></callhistory>
                </el-tab-pane>
                <el-tab-pane label="维保记录" name="wbjl">
                    <mainrecord></mainrecord>
                </el-tab-pane>
                <el-tab-pane label="应急处置记录" name="yjczjl">1</el-tab-pane>
                <el-tab-pane label="投诉处置记录" name="tsczjl">2</el-tab-pane>
                <el-tab-pane label="通知消息" name="tzxx">3</el-tab-pane>
            </el-tabs>
            
        </el-dialog>
        <el-dialog 
            width="1080px"
            :visible.sync="videoListVisible"
            title="电梯监控"
            center
            @close="refreeVideo"
            :close-on-click-modal="false">
            <div class="v_lists flex f_start f_warp">
                <div class="v_item" :class="{'small':pageData2.pageSize==4}" v-for="(item,index) in v_lists" :key="index">
                    <div class="video_box">
                        <!-- <videoPlayer class="vjs-custom-skin videoPlayer" ref="videoplayer" :playsinline="true" width="100%" height="100%" :options="item" customEventName="changed"></videoPlayer> -->
                        <videoPlayer
                            class="view"
                            :ref="'video'+index"
                            :videoItem="item.v_info"
                            :key="item.DataTimeKeys"
                            v-if="item&&item.v_info&&item.v_info!=''"
                        />
                    </div>
                    <span class="if-box video_title">
                        {{item.name}}
                    </span>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-pagination
                    @size-change="handleSizeChange3"
                    @current-change="handleCurrentChange3"
                    :current-page.sync="pageData2.page"
                    :page-sizes="[1,4]"
                    :page-size="pageData2.pageSize?pageData2.pageSize:0"
                    layout="sizes, prev, pager, next"
                    :total="pageData2.total?pageData2.total:0">
                </el-pagination>
            </div>  
        </el-dialog>
    </div>
</template>
<script>
import {villageOption} from "@/collocat/options.js"
import {liftOption} from "@/collocat/lift.js";
import { getData,getDic,postData} from '@/api/mc.js';
import {dataview,archives,syndata,callhistory,mainrecord,alarm} from "./component/index.js"
import videoPlayer from "@/components/videoPlayer";
import {export2Excel} from '@/utils/export2Excel.js'
export default {
    name:'village',
    components:{
        dataview,
        archives,
        syndata,
        callhistory,
        mainrecord,
        alarm,
        videoPlayer
    },
    data(){
        return{
            tableOption:villageOption,
            tableData: [],
            liftOption:liftOption,
            liftData:[],
            pageData:{
                pageSize:'',
                page:1,
                total:'',
            },
            pageData1:{
                pageSize:'',
                page:1,
                total:'',
            },
            pageData2:{
                pageSize:1,
                page:1,
                total:'',
            },
            // props: {
            //     checkStrictly: true,
            //     lazy: true,
            //     lazyLoad (node, resolve) {
            //         const { level } = node;
            //         let temp = {
            //             face:'getAddrList',
            //             data:{
            //                 pid:53
            //             }
            //         }
            //         if(level>0){
            //             temp.data.pid = node.value
            //         }
            //         getData(temp).then(res=>{
            //             if(res.success){
            //                 const nodes = res.data
            //                 .map(item => ({
            //                     value: item.id,
            //                     label: item.name,
            //                     leaf: level >= 2
            //                 }));
            //             // 通过调用resolve将子节点数据返回，通知组件数据加载完成
            //             resolve(nodes);
            //             }
            //         })
            //     }
            // },
            areList:[],
            rowForm:{},
            searchForm:{},
            searchForm1:{},
            infoForm:{},
            formType:'',
            infoVisible:false,
            liftVisible:false,
            needVisible:false,
            dataVisible:false,
            videoListVisible:false,
            flvPlayer: null,
            v_lists:[],
            spaceOpt:[
                {label:'一个月',value:1},
                {label:'两个月',value:2},
                {label:'三个月',value:3},
            ],
            activeTab:'gl',
            itemInfo:'',
            showData:false,
            loading:false,
            setVisible:false,
            menuPro:'',
            showFilter_coll:false,
            // HLSCore
        }
    },
    created(){
        // var m = window.sessionStorage.getItem('pageParms')//this.$store.state.pageParms;//this.$route.params
        // var pm = ''
        // if(m&&m!=''){
        //     pm = JSON.parse(m)
        // }
        // console.log(pm)
        // if(pm&&pm!=''){
        //     var fm = JSON.parse(JSON.stringify(this.searchForm));
        //     for (var key in pm) {
        //         if (Object.hasOwnProperty.call(pm, key)) {
        //             fm[key] = pm[key]
        //         }
        //     }
        //     this.$set(this,'searchForm',fm)
        //     console.log(this.searchForm)
        // }
        // this.getList()
        // this.getArees()
    },
    computed: {
        isFollow () {
            if(this.$store.state.menuPromise&&this.$store.state.menuPromise!=''){
                let arr = JSON.parse(this.$store.state.menuPromise)||{};
                let temp = {};
                for(var i=0;i<arr.length;i++){
                    temp[arr[i].permission] = arr[i]
                }
                this.$set(this,'menuPro',temp)
            }
            return this.$store.state.menuPromise;　　//需要监听的数据
        }
    },
    watch: {
        isFollow (newVal, oldVal) {
        }
    },
    methods:{
        getArees(){
            var temp = {
                face:'getAddrList',
                data:{
                    pid:53
                }
            }
            // if(level>0){
            //     temp.data.pid = node.value
            // }
            getData(temp).then(res=>{
                if(res.success){
                    var arr1 = []
                    for (var i = 0; i < res.data.length; i++) {
                        var item = res.data[i];
                        var tmp = {
                            value: item.id,
                            label: item.name,
                        }
                        arr1.push(tmp)
                    }
                    this.$set(this,'areList',arr1)
                    // const nodes = res.data
                    // .map(item => ({
                    //     value: item.id,
                    //     label: item.name,
                    //     leaf: level >= 2
                    // }));
                // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                // resolve(nodes);
                }
            })
        },
        getList(){
            this.loading = true
            let temp = {
                face:'getVallige',
                data:{
                    pageNum:this.pageData.page,
                    pageSize:this.pageData.pageSize,
                }
            }
            for (var key in this.searchForm) {
                if (Object.hasOwnProperty.call(this.searchForm, key)) {
                   temp.data[key] =  this.searchForm[key]
                }
            }
            if(temp.data.onlyRoad == false){
                temp.data.onlyRoad = ''
            }
            getData(temp).then(res=>{
                this.loading = false
                if(res.success){
                    this.$set(this,'tableData',res.data.data)
                    let tmp = {
                        pageSize:this.pageData.pageSize,
                        page:this.pageData.page,
                        total:res.data.totalCount,
                    }
                    this.$set(this,'pageData',tmp)
                }
            })
        },
        getLiftVideo(){
            let temp = {
                face:'getParkLiftVideoList',
                data:{
                    pageNum:this.pageData2.page,
                    pageSize:this.pageData2.pageSize,
                }
            }
            temp.data.parkId = this.rowForm.id
            getData(temp).then(res=>{
                this.loading = false
                if(res.success){
                    var arr = [];
                    if(this.pageData2.pageSize == 4){
                        if(res.data.records.length<4) {
                            for (var i = 0; i < 4; i++) {
                                if(res.data.records[i]&&res.data.records[i]!=''){
                                    arr.push(res.data.records[i])
                                }else{
                                    arr.push({})
                                }
                            }
                        }else{
                            arr = res.data.records
                        }
                    }else{
                        arr = res.data.records
                    }
                    var arr1 = [];
                    for (var i = 0; i < arr.length; i++) {
                        if(arr[i].recUrl&&arr[i].recUrl!=''){
                            arr[i].video_type='live'
                            var tp = {
                                DataTimeKeys:new Date().getTime(),
                                v_info:arr[i],
                            }
                            arr1.push(tp)
                        }else{
                            arr1.push('')
                        }
                    }
                    this.$set(this,'v_lists',arr1)
                    
                    // this.setVideoList()
                    let tmp = {
                        pageSize:this.pageData2.pageSize,
                        page:this.pageData2.page,
                        total:res.data.total,
                    }
                    console.log(tmp)
                    this.$set(this,'pageData2',tmp)
                }
            })
        },
        changeColumnOption(parms){
            this.$set(this.tableOption,'columns',parms)
        },
        handleCurrentChange(page){
            this.$set(this.pageData,'page',page)
            this.getList()
        },
        changeSearchOption(parms){
            this.$set(this.tableOption.searchcolumns[parms.index],'column',parms.list)
        },
        changeSearchOption1(parms){
            this.$set(this.liftOption.searchcolumns[parms.index],'column',parms.list)
        },
        handleSizeChange(val){
            this.$set(this.pageData,'pageSize',val)
            this.$set(this,'tableData',[])
            this.getList()
        },
        handleSizeChange3(val){
            this.$set(this.pageData2,'pageSize',val)
            this.$set(this,'v_lists',[])
            this.getLiftVideo()
        },
        handleCurrentChange3(page){
            this.$set(this.pageData2,'page',page)
            this.getLiftVideo()
        },
        pageRefree(){
            this.$set(this.pageData,'page',1)
        },
        setSearchRefree(){
            var m = JSON.parse(JSON.stringify(this.searchForm))
            for (var key in m) {
                if (Object.hasOwnProperty.call(m, key)) {
                    m[key] = ''
                }
            }
            this.$set(this,'searchForm',m)
        },
        searchHandle1(){
            this.getLiftList();
        },
        searchHandle(parms){
            var m = JSON.parse(JSON.stringify(this.searchForm));
            for (var key in parms) {
                if (Object.hasOwnProperty.call(parms, key)) {
                    m[key] = parms[key]
                }
            }
            this.$set(this.pageData,'page',1)
            this.$set(this,'searchForm',m)
            this.getList();
        },
        handleCurrentChange1(page){
            this.$set(this.pageData1,'page',page)
            this.getList()
        },
        selectionChangeHandle(params){
            let arr = JSON.parse(JSON.stringify(params));
            let arr1 = []
            for (let index = 0; index < arr.length; index++) {
                arr1.push(arr[index].id)
            }
            this.$set(this.rowForm,'ids',arr1)
        },
        getInfo(row,t){
            if(t==1){
                let temp = {
                    face:'valligeInfo',
                    data:{
                        id:row.row.id
                    }
                }
                getData(temp).then(res=>{
                    if(res.success){
                        this.$set(this,'infoForm',res.data)
                        this.infoVisible = true
                    }
                })
            }else if(t == 3){
                var temp = {
                    isParms:true,
                    data:{
                        parkName:row.row.parkName,
                        // parkId:row.row.id
                    }
                }
                this.$parent.setRout(17,temp);
                // this.$router.push({ name: 'lift' })

                // this.$set(this,'rowForm',row.row)
                // this.getLiftList()
            }else if(t==2){
                //获取监控列表
                this.$set(this,'rowForm',row.row)
                this.videoListVisible = true
                this.getLiftVideo()
            }else if(t == 4){
                var temp = {
                    isParms:true,
                    data:{
                        parkName:row.row.parkName,
                        parkId:row.row.id
                    }
                }
                this.$parent.setRout(82,temp);
            }else if(t == 7){
                this.$set(this,'rowForm',row.row)
                this.exportMcList()
            }
        },
        getLiftList(){
            let temp = {
                face:'liftList',
                data:{
                    parkId:this.rowForm.id,
                    key:'',
                    orderBy:'',
                    pageNum:'',
                    pageSize:'',
                }
            }
            for (var key in this.searchForm1) {
                if (Object.hasOwnProperty.call(this.searchForm1, key)) {
                    temp.data[key] =  this.searchForm1[key]
                }
            }
            getData(temp).then(res=>{
                if(res.success){
                    this.$set(this,'liftData',res.data.data)
                    let temp = {
                        pageSize:20,
                        page:this.pageData1.page,
                        total:res.data.totalCount,
                    }
                    this.$set(this,'pageData1',temp)
                    this.liftVisible = true
                }
            })
        },
        refreeVideo(){
            this.$refs.video.onPlayerPause();
            this.$set(this,'v_lists',[])
        },
        refreeForm(){
            this.$set(this,'searchForm1',{})
            this.$set(this,'rowForm',{})
            this.$set(this.pageData2,'page',1)
        },
        handleClick(tab, event){
            console.log(tab, event);
        },
        setSys(row,t){
            var flag = false;
            this.$set(this,'formType',t);
            if(t == 1){
                if(this.rowForm.ids&&this.rowForm.ids.length>0){
                    flag = true;
                }else{
                    this.$message({
                        message: '请勾选维保电梯',
                        type: 'warning'
                    });
                }
            }else if(t == 3){
                this.$set(this,'rowForm',row.row);
                flag = true;
            }
            if(flag){
                if(t!=3){
                    this.needVisible = true
                }else{
                    this.getItemInfo()
                }
            }
        },
        getItemInfo(){
            var temp = {
                face:'liftInfo',
                data:{
                    id:this.rowForm.id
                }
            }
            getData(temp).then(res=>{
                if(res.success){
                    this.$set(this,'itemInfo',res.data)
                    this.dataVisible = true
                    this.$set(this,'showData',true)
                }
            })
        },
        submit(){
            if(this.formType == 1){
                var temp = {
                    face:'openMcNeed',
                    data:{
                        ids:this.rowForm.ids.join(','),
                        month:this.rowForm.month,
                        remark:this.rowForm.remark
                    }
                }
                postData(temp).then(res=>{
                    this.$message({
                        message: res.message,
                        type: res.success?'success':'error'
                    });
                    if(res.success){
                        this.$refs.schedule.clearSelection()
                        this.needVisible = false
                    }
                })
            }
        },
        shetFilternav(){
            var f = this.showFilter_coll
            this.$set(this,'showFilter_coll',!f)
        },
        handleCommand(command) {
            if(command == 'clear'){
                this.$set(this,'searchForm',{})
            }
        },
        refreeSetForm(){
            // this.$set(this,'infoForm',{})
        },
        subSetInfo(){
            // if(!this.infoForm.anotherName||this.infoForm.anotherName==''){
            //     this.$message({
            //         message: '备注名称不能为空',
            //         type: 'error'
            //     });
            //     return false
            // }
            var temp = {
                face:'setValligeInfo',
                data:{
                    id:this.infoForm.id,
                    anotherName:this.infoForm.anotherName
                }
            }
            postData(temp).then(res=>{
                this.$message({
                    message: res.message,
                    type: res.success?'success':'error'
                });
                if(res.success){
                    this.setVisible = false;
                    this.infoVisible = false;
                    this.getList();
                }
            })
            
        },
        setOnlyType(e){
            if(e==true){
                this.searchForm.road = ''
            }
        },
        exportMcList(){
            var temp = {
                face:'getParkMcList',
                data:{
                    parkId:this.rowForm.id,
                    responseType:'arraybuffer'
                }
            }
            this.loading = true
            getData(temp).then(res=>{
                this.loading = false
                if(res){
                    var a = document.createElement('a');//在dom树上创建一个a标签
                    var binaryData = [];
                    binaryData.push(res);
                    var url =window.URL.createObjectURL(new Blob(binaryData,{ type: 'application/vnd.ms-excel' }));
                    a.href = url;//将url赋值给a标签的href属性
                    a.download =this.rowForm.parkName+'小区年度维保计划.xls';
                    a.click();//主动触发a标签点击事件
                }
            })
        },
    },
    activated() {
        var m = window.sessionStorage.getItem('pageParms')//this.$store.state.pageParms;//this.$route.params
        var pm = ''
        if(m&&m!=''){
            pm = JSON.parse(m)
        }
        console.log(pm)
        if(pm&&pm!=''){
            var fm = JSON.parse(JSON.stringify(this.searchForm));
            for (var key in pm) {
                if (Object.hasOwnProperty.call(pm, key)) {
                    fm[key] = pm[key];
                    this.$refs['pageTable'].searchForm[key] = pm[key]
                }
            }
            this.$set(this,'searchForm',fm)
        }
        this.getList()
　　},

}
</script>
<style lang="less" scoped>
.info_panel{
    li{
        width: 100%;
        border-bottom: 1px solid #e5e5e5;
        height: 36px;
        font-size: 12px;
        position: relative;
        label{
            width: 100px;
            text-align: right;
            padding-right: 10px;
            font-weight: 600;
        }
        span{
            font-weight: 400;
            color: #666666;
        }
        em{
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            right: 30px;
            color: #409EFF;
        }
    }
}
.v_lists{
    width: 100%;
    height: 700px;
    .v_item{
        width: 100%;
        height: 100%;
        position: relative;
        &.small{
            width: 50%;
            height: 50%;
            border: 1px solid #ffffff;
        }
        .video_box{
            width: 100%;
            height: 100%;
            // /deep/div{
            //     width: 100%;
            //     height: 100%;
            // }
        }
        .video_title{
            position: absolute;
            top: 10px;
            left: 10px;
            color: #ffffff;
            font-size: 14px;
        }
    }
}
</style>