<template>
    <div class="mtcpro">
        <div class="flex f_between pro_tab">
            <el-radio-group v-model="radio4" size="mini">
                <el-radio-button label="1">机房<i>(5)</i></el-radio-button>
                <el-radio-button label="2">轿厢<i>(7)</i></el-radio-button>
                <el-radio-button label="3">轿顶<i>(0)</i></el-radio-button>
                <el-radio-button label="4">层门<i>(3)</i></el-radio-button>
                <el-radio-button label="5">井道<i>(2)</i></el-radio-button>
            </el-radio-group>
            <span>
                <el-button type="primary">引用已有模板</el-button>
                <el-button type="primary"  icon="el-icon-s-management">存为模板</el-button>
            </span>
        </div>
        <div class="pro_list">
            <div class="pro_item flex f_between">
                <div class="pro_item_info">
                    <h5>01 机房、机房、滑轮间环境</h5>
                    <p class="t_99">清洁、门窗完好、照明正常</p>
                </div>
                <div class="if-box item_tab">
                    <label class="if-box">
                        <i class="el-icon-bottom"></i>
                    </label>
                    <label class="if-box">
                        <i class="el-icon-top"></i>
                    </label>
                    <label class="if-box">
                        <i class="el-icon-delete"></i>
                    </label>
                </div>
            </div>
            <div class="pro_item flex f_between add">
                <div class="pro_item_info">
                    <h5>
                        <el-input v-model="title" placeholder="请输入标题"></el-input>
                    </h5>
                    <p class="t_99"><el-input v-model="subtitle" placeholder="请输入说明"></el-input></p>
                </div>
                <div class="if-box item_tab">
                    <el-button type="danger" plain>确定</el-button>
                </div>
            </div>
        </div>
        <div class="pro_add_icon">
            <label class="if-box">
                <i class="el-icon-circle-plus"></i>
                添加维保项
            </label>
        </div>
    </div>
</template>
<script>
export default {
    name:'stepstwo',
    data(){
        return{
            radio4:'',
            title:'',
            subtitle:''
        }
    },
    methods:{
         addList(){
            this.$nextTick(() => { 
                //必须使用nextTick（在下次 DOM 更新循环结束之后执行延迟回调）
                var divDom = this.$refs.divDom;
                divDom .scrollTop = divDom .scrollHeight;
            })
        }
    }
}
</script>
<style lang="less" scoped>
/deep/.el-radio-group{
    .el-radio-button__orig-radio:checked+.el-radio-button__inner{
        background-color: #3AD5B7;
        border-color: #3AD5B7;
        box-shadow: -1px 0 0 0 #3AD5B7;
        &:hover{
            color: #ffffff;
        }
    }
    .el-radio-button__inner{
        border-color: #E2EEFB;
        font-size: .14rem;
        i{
            font-size: .10rem;
            vertical-align: text-bottom;
        }
        &:hover{
            color: #3AD5B7;
        }
    }
}
.pro_list{
    padding: .2rem 0;
    .pro_item{
        width: 100%;
        height: .83rem;
        padding: 0 .2rem;
        background: #FBFCFE;
        border: 1px solid #E2EEFB;
        opacity: 1;
        border-radius: .06rem;
        margin-bottom: .2rem;
        .pro_item_info{
            width: 50%;
            h5{
                line-height: .22rem;
                font-size: .16rem;
                font-weight: bold;
                margin-bottom: .05rem;
                /deep/.el-input{
                    border-bottom: 1px solid #333333;
                    .el-input__inner{
                        border: none;
                        font-size: .16rem;
                        color: #333333;
                        // height: .22rem !important;
                        // line-height: .22rem !important;
                        &::placeholder{
                            color: #333333;
                        }
                    }
                }
            }
            p{
                line-height: .2rem;
                font-size: .14rem;
                font-weight: 400;
                /deep/.el-input{
                    // border-bottom: 1px solid #999999;
                    .el-input__inner{
                        // border: none;
                        color: #999999;
                        font-size: .12rem;
                        // height: .2rem !important;
                        // line-height: .2rem !important;
                    }
                }
            }
        }
        .item_tab{
            label{
                visibility: hidden;
                width: .2rem;
                height: .2rem;
                margin-right: .1rem;
                cursor: pointer;
                i{
                    font-size: .18rem;
                    font-weight: 600;
                }
                &:last-of-type{
                    margin-right: 0;
                }
            }
        }
        &.add{
            height: 1.1rem;
            .pro_item_info{
                h5{
                    margin-bottom: .1rem;
                }
            }
        }
        &:hover,&:active{
           .item_tab{
               label{
                    visibility: inherit;
               }
           } 
        }
        &:last-of-type{
            margin-bottom: 0;
        }
    }
}
.pro_add_icon{
    label{
        font-size: .16rem;
        font-weight: 500;
        color: #0278F1;
        cursor: pointer;
        i{
            font-size: .18rem;
            margin-right: .07rem;
        }
    }
}
</style>