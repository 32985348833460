import { Select } from "element-ui"

export const liftOption = {
    addShow:false,//展示顶部操作栏
    showMenu:true,//展示右侧操作列
    showPage:true,//展示分页器
    selection:true,
    showDraw:true,
    menuWidth:280,
    // mtHeight:600,
    columns:[
        {
            label:'小区名称',
            prop:"parkName",
            custom:true,
        },{
            label:'梯号',
            prop:"liftName",
            custom:true
        },
        {
            label:'所在区域',
            prop:"cityName",
            custom:true,
        },
        {
            label:'注册代码',
            prop:"sn",
        },
        {
            label:'电梯编码',
            prop:"code",
        },{
            label:'电梯状态',
            prop:"status",
            type:'select',
            column:[
                {
                    label:'正常',
                    value:1
                },
                {
                    label:'停用',
                    value:2
                }
            ]
        },{
            label:'品牌',
            prop:"devVnd",
        },{
            label:'物业单位',
            prop:"pmCorpName",
            custom:true,
        },{
            label:'维保单位',
            prop:"mcCorpName",
        },{
            label:'维保类型',
            prop:"mcType",
            type:'select',
            column:[
                {
                    label:'按需维保',
                    value:true
                },
                {
                    label:'定期维保',
                    value:false
                }
            ]
        }
    ],
    searchcolumns:[
        {
            label:'小区名称',
            prop:"parkName",
        },{
            label:'维保单位名称',
            prop:"mcCorpName",
        },{
            label:'物业名称',
            prop:"pmCorpName",
        },{
            label:'电梯编码',
            prop:"code",
        },{
            label:'地区',
            prop:"areaId",
            type:'select',
            // searchcustom:true
            column:[],
            dicUrl:'getAddrList',
            getType:'test',
            getParams:{
                pid:53
            },
            propformat:{
                label:'name',
                value:'id'
            }
        },{
            label:'使用场所',
            prop:"devCate",
            // searchcustom:true
            type:'select',
            column:[],
            dicUrl:'liftType',
            dicKey:'lift.cate'
        },{
            label:'注册代码',
            prop:"sn",
        },{
            label:'品牌',
            prop:"devVnd",
            // searchcustom:true
            type:'select',
            column:[],
            dicUrl:'liftType',
            dicKey:'lift.vendor'
        },{
            label:'维保类型',
            prop:"need",
            type:'select',
            column:[{label:'不限',value:0},{label:'按需',value:1},{label:'定期',value:2}]
        },{
            label:' ',
            prop:"onlyInst",
            type:'checkbox',
            text:'只显示智慧电梯'
            // placeholder:'只显示智慧电梯与否',
            // type:'select',
            // column:[
            //     {
            //         label:'只显示智慧电梯',
            //         value:true
            //     },{
            //         label:'不限制',
            //         value:false
            //     },
            // ],
        },{
            label:' ',
            prop:"status",
            type:'checkbox',
            trueLabel:"2", 
            falseLabel:"1",
            text:'只显示已停用电梯'
            // placeholder:'只显示已停用电梯与否',
            // type:'select',
            // column:[
            //     {
            //         label:'只显示已停用电梯',
            //         value:2
            //     },{
            //         label:'不限制',
            //         value:1
            //     },
            // ],
        },{
            label:' ',
            prop:"isNfc",
            type:'checkbox',
            trueLabel:"1", 
            falseLabel:"0",
            text:'只显示NFC维保试点电梯'
            // placeholder:'只显示已停用电梯与否',
            // type:'select',
            // column:[
            //     {
            //         label:'只显示已停用电梯',
            //         value:2
            //     },{
            //         label:'不限制',
            //         value:1
            //     },
            // ],
        },
    ]
}
/**
 * 定期维保配置
 */
export const mcRegularOption = {
    showMenu:true,//展示右侧操作列
    showPage:true,//展示分页器
    showSearch:true,
    selection:true,
    stripe:true,
    tHeight:650,
    menuWidth:140,
    columns:[
        {
            label:'区域',
            prop:'areaName'
        },{
            label:'小区名称',
            prop:'parkName',
            custom:true
        },{
            label:'电梯',
            prop:'devName',
            custom:true
        },{
            label:'电梯编码',
            prop:'liftCode'
        },{
            label:'注册代码',
            prop:'sn'
        },{
            label:'维保员',
            prop:'rcMcMan',
        },{
            label:'维保员电话',
            prop:'rcMcPhone',
        },
        {
            label:'下次维保时间',
            prop:'nextMc'
        },
        // {
        //     label:'下次维保类型',
        //     prop:'tmpl'
        // },
        // {
        //     label:'上次维保距今困人次数',
        //     prop:'tiringNum'
        // },{
        //     label:'上次维保距今故障次数',
        //     prop:'faultNum'
        // },
    ],
    searchcolumns:[
        {
            label:'排期',
            prop:'scheduling',
            type:'select',
            defaultValue:1,
            column:[
                {
                    label:'未排期',
                    value:0
                },{
                    label:'已排期',
                    value:1
                }
            ],
        },{
            label:'小区',
            prop:'parkName',
        },{
            label:'下次维保时间',
            prop:'timer',
            searchType:'date',
            valueFormat:'yyyy-MM-dd',
            // searchcustom:true,
            pickerOptions:{
                disabledDate(time) {//禁用当前日期之前的日期
                    var t = new Date().getTime()
                    var m = time<new Date(t- (24*60*60*1000))
                    var m1 = time>new Date(t+(24*60*60*1000)*14)
                    return m1||m;
                },
            },
        },
        // {
        //     label:'维保员',
        //     prop:'mcName',
        // },{
        //     label:'维保员电话',
        //     prop:'mcPhone',
        // },
        {
            label:'维保员电话',
            prop:'mcPhone'
        },{
            label:'地区',
            prop:'areaId',
            type:'select',
            column:[],
            dicUrl:'areaByPid',
            // dicKey:'lift.type'
            getType:'test',
            getParams:{
                pid:53
            },
            propformat:{
                label:'name',
                value:'id'
            }
        },{
            label:'电梯编码',
            prop:'liftCode'
        },{
            label:'注册代码',
            prop:'sn'
        }
    ]
}
export const mcReportOption = {
    basicBorder:true,
    showPage:true,
    stripe:true,
    mtHeight:650,
    columns:[
        {
            label:'工单类型',
            prop:'tmplName'
        },{
            label:'工单编号',
            prop:'id'
        },{
            label:'项目名称',
            prop:'parkName'
        },{
            label:'梯号',
            prop:'ono'
        },{
            label:'电梯编码',
            prop:'liftCode'
        },{
            label:'工单提交时间',
            prop:'mcDay'
        },{
            label:'维保员姓名',
            prop:'mcMan'
        },{
            label:'开始时间',
            prop:'mcSta'
        },{
            label:'结束时间',
            prop:'mcEnd'
        },
        // {
        //     label:'项目地址',
        //     prop:'addr'
        // },
        {
            label:'是否超期',
            prop:'cq',
            custom:true
        },{
            label:'维保时长',
            prop:'long',
            custom:true
        },{
            label:'维保图片',
            prop:'imgs',
            custom:true,
        },
    ],
    searchcolumns:[]
}
/**
 * 维保模板页面配置
 */
export const mcModeOption = {
    addShow:false,//展示顶部操作栏
    showMenu:true,//展示右侧操作列
    showPage:true,//展示分页器
    selection:false,
    showSearch:true,
    tHeight:650,
    menuWidth:120,
    columns:[
        {
            label:'模板名称',
            prop:'type',
            type:'select',
            column:[],
            dicUrl:'liftType',
            dicKey:'dev.mc.tmpl.type'
        },
        {
            label:'电梯类型',
            prop:'devType',
            type:'select',
            column:[],
            dicUrl:'liftType',
            dicKey:'lift.type'
        },
        {
            label:'项目数量',
            prop:'itemNum',
        },
        {
            label:'最后更新时间',
            prop:'dtm',
        },
    ],
    searchcolumns:[
        {
            label:'电梯类型',
            prop:'liftType',
            type:'select',
            column:[],
            dicUrl:'liftType',
            dicKey:'lift.type'
        },
    ]
}
export const NeedModeOption = {
    addShow:false,//展示顶部操作栏
    showMenu:true,//展示右侧操作列
    showPage:true,//展示分页器
    selection:false,
    showSearch:true,
    // tHeight:650,
    menuWidth:380,
    columns:[
        {
            label:'模板名称',
            prop:'name',
        },
        {
            label:'电梯类型',
            prop:'devType',
            type:'select',
            column:[],
            dicUrl:'liftType',
            dicKey:'lift.type'
        },
        {
            label:'项目数量',
            prop:'num',
        },{
            label:'是否默认',
            prop:'def',
            type:'select',
            column:[
                {
                    label:'默认',
                    value:true
                },{
                    label:'否',
                    value:false
                },
            ]
        },
        {
            label:'最后更新时间',
            prop:'udtm',
        },
    ],
    searchcolumns:[
        {
            label:'电梯类型',
            prop:'liftType',
            type:'select',
            column:[],
            dicUrl:'liftType',
            dicKey:'lift.type'
        },
    ]
}
export const mcMenuOption = {
    showMenu:true,//展示右侧操作列
    showindex:true,
    menuWidth:120,
    // showHeader:false,
    stripe:true,
    // mtHeight:500,
    customShearchClass:true,
    showRowClassName:true,
    dialogTable:true,
    RowClassrender:(row)=>{
        if(row.checkd == false){
            return 'danger-row';
        }
    },
    columns:[
        {
            label:'说明',
            prop:"name",
            minWidth:2
        },{
            label:'类型',
            prop:"cate",
            minWidth:2,
            type:'select',
            column:[],
            dicUrl:'liftType',
            dicKey:'dev.mc.tmpl.cate'
        },{
            label:'标准',
            prop:"need",
            minWidth:6
        }
    ],
    searchcolumns:[]
}
/**
 * 按需维保列表配置
 */
export const mcNeedOption={
    showMenu:true,//展示右侧操作列
    showPage:true,//展示分页器
    showSearch:true,
    stripe:true,
    tHeight:650,
    menuWidth:240,
    columns:[
        {
            label:'类型',
            prop:'leval',
            custom:true,
            customClassName:'f_r',
            minWidth:'20'
        },{
            label:'区域',
            prop:'areaName'
        },{
            label:'小区',
            prop:'parkName'
        },{
            label:'电梯',
            prop:'devName'
        },{
            label:'电梯编码',
            prop:'liftCode'
        },{
            label:'下次维保时间',
            prop:'nextMc'
        },{
            label:'新发生',
            prop:'newNum'
        },{
            label:'未解决',
            prop:'tiringNum'
        }
    ],
    searchcolumns:[
        {
            label:'地区',
            prop:'areaId',
            type:'select',
            column:[],
            dicUrl:'areaByPid',
            // dicKey:'lift.type'
            getType:'test',
            getParams:{
                pid:53
            },
            propformat:{
                label:'name',
                value:'id'
            }
        },{
            label:'小区',
            prop:'parkId',
        },{
            label:'电梯编码',
            prop:'liftCode',
        },{
            label:'处理类型',
            prop:'t',
            type:'select',
            column:[
                {
                    label:'全部',//1：全部；2：紧急处理；3：维保临期；4新发生；5已派单；6未解决
                    value:1
                },{
                    label:'紧急处理',
                    value:2
                },{
                    label:'维保临期',
                    value:3
                },{
                    label:'新发生',
                    value:4
                },{
                    label:'已派单',
                    value:5
                },{
                    label:'未解决',
                    value:6
                }
            ],
        },
    ]
}
export const MenuNeedOption = {
    showMenu:true,//展示右侧操作列
    selection:true,
    stripe:true,
    // showSearch:true,
    customShearchClass:true,
    mtHeight:500,
    dialogTable:true,
    menuWidth:120,
    columns:[
        {
            label:'分类',
            prop:"cate",
            minWidth:1,
            type:'select',
            column:[],
            dicUrl:'liftType',
            dicKey:'dev.mc.tmpl.cate'
        },{
            label:'项目名称',
            prop:"name",
            minWidth:2
        },{
            label:'项目说明',
            prop:"need",
            minWidth:6
        }
    ],
    searchcolumns:[
        // {
        //     label:'',
        //     labelWidth:'0px',
        //     prop:'liftType',
        //     type:'select',
        //     column:[],
        //     dicUrl:'liftType',
        //     dicKey:'lift.type',
        //     placeholder:'请选择电梯类型'
        // },
    ]
}
export const customNeedOption = {
    // showMenu:true,//展示右侧操作列
    // selection:true,
    stripe:true,
    // showSearch:true,
    // customShearchClass:true,
    mtHeight:300,
    menuWidth:120,
    columns:[
        {
            label:'分类',
            prop:"cate",
            minWidth:1,
            type:'select',
            column:[],
            dicUrl:'liftType',
            dicKey:'dev.mc.tmpl.cate'
        },{
            label:'项目名称',
            prop:"name",
            minWidth:2
        },{
            label:'项目说明',
            prop:"need",
            minWidth:6
        }
    ],
    searchcolumns:[]
}
/**
 * 按需维保配置项页面配置
 */
export const mcNeedModeOption = {
    showMenu:true,//展示右侧操作列
    showPage:true,//展示分页器
    tHeight:650,
    menuWidth:120,
    showSearch:true,
    columns:[
        {
            label:'分类',
            prop:'cate',
            type:'select',
            column:[],
            dicUrl:'liftType',
            dicKey:'dev.mc.tmpl.cate'
        },{
            label:'电梯类型',
            prop:'devType',
            type:'select',
            column:[],
            dicUrl:'liftType',
            dicKey:'lift.type'
        },{
            label:'维保项',
            prop:'name',
        },{
            label:'维保要求',
            prop:'need',
        }
    ],
    searchcolumns:[
        {
            label:'电梯类型',
            prop:'liftType',
            type:'select',
            column:[],
            dicUrl:'liftType',
            dicKey:'lift.type'
        }
    ]
}
export const mcNeedMenuOption = {
    showMenu:true,//展示右侧操作列
    selection:true,
    stripe:true,
    showSearch:true,
    customShearchClass:true,
    mtHeight:500,
    dialogTable:true,
    menuWidth:120,
    columns:[
        {
            label:'项目名称',
            prop:"name",
            minWidth:2
        },{
            label:'项目说明',
            prop:"need",
            minWidth:6
        }
    ],
    searchcolumns:[
        {
            label:'',
            labelWidth:'0px',
            prop:'liftType',
            type:'select',
            column:[],
            dicUrl:'liftType',
            dicKey:'lift.type',
            placeholder:'请选择电梯类型'
        },
    ]
}
/**
 * 维保审核列表配置
 */
export const mcExamOption = {
    showMenu:true,//展示右侧操作列
    stripe:true,
    showPage:true,//展示分页器
    tHeight:750,
    menuWidth:120,
    columns:[
        {
            label:'维保类型',
            prop:'tmplName'
        },{
            label:'小区',
            prop:'parkName'
        },{
            label:'电梯',
            prop:'ono'
        },{
            label:'维保人',
            prop:'mcMan'
        },{
            label:'维保提交时间',
            prop:'mcEnd'
        },{
            label:'状态',
            prop:'audtTagOk',
            custom:true
        }
    ],
    searchcolumns:[]
}
export const mcOverExamOption = {
    showMenu:true,//展示右侧操作列
    stripe:true,
    showPage:true,//展示分页器
    menuWidth:120,
    selection:true,
    columns:[
        {
            label:'名称',
            prop:'name'
        },{
            label:'维保类型',
            prop:'tmplType'
        },{
            label:'维保公司',
            prop:'rcMcCorpName'
        },{
            label:'物业公司',
            prop:'rcPmCorpName'
        },{
            label:'排期时间',
            prop:'dtm'
        },{
            label:'电梯编码',
            prop:'code'
        },{
            label:'维保时间',
            prop:'mcDay',
        },{
            label:'超期时间',
            prop:'overDays',
            custom:true
        }
    ],
    searchcolumns:[
        {
            label:'超期类型',
            prop:'status',
            type:'select',
            column:[
                {
                    label:'超期未做',
                    value:0
                },{
                    label:'超期已做',
                    value:1
                },
            ],
            defaultValue:0
        },{
            label:'小区名称',
            prop:'parkName',
        },{
            label:'',
            prop:'other',
            searchcustom:true
        },{
            label:'电梯编码',
            prop:'liftCode',
        },{
            label:'维保单位',
            prop:'mcCorp',
        },{
            label:'物业单位',
            prop:'pmCorp',
        },{
            label:'清理状态',
            prop:'state',
            type:'select',
            defaultValue:0,
            column:[
                {
                    label:'未清理',
                    value:0
                },{
                    label:'已清理',
                    value:1
                },
            ],
        },{
            label:'排期时间',
            prop:'timer',
            searchType:'daterange',
            valueFormat:"yyyy-MM-dd",
        },
    ]
}
export const mcExamInfoOption = {
    stripe:true,
    showindex:true,
    mtHeight:500,
    border:true,
    columns:[
        {
            label:'维保要求',
            prop:'need',
            custom:true,
            minWidth:2,
        },{
            label:'备注',
            prop:'m',
            minWidth:1,
        },{
            label:'维保结果',
            prop:'status',
            width:'80',
            custom:true,
            align:'center'
        },
    ],
    searchcolumns:[]
}
/**
 * 维保列表 视图详情列表
 */
export const mcListViewOption = {
    showPage:true,//展示分页器
    tHeight:630,
    showMenu:true,//展示右侧操作列
    basicBorder:true,
    stripe:true,
    menuWidth:120,
    selection:true,
    columns:[

        {
            label:'区域',
            prop:'areaName'
        },{
            label:'小区',
            prop:'parkName',
            custom:true
        },{
            label:'梯号',
            prop:'ono',
            custom:true
        },
        {
            label:'排期日期',
            prop:'dtm'
        },{
            label:'维保日期',
            prop:'mcDay'
        },{
            label:'排期类型',
            prop:'oldTmpl',
            custom:true
        },{
            label:'维保类型',
            prop:'tmplName',
        },{
            label:'设备',
            prop:'liftCode'
        },{
            label:'维保人',
            prop:'mcMan'
        },{
            label:'审核人',
            prop:'audtMan'
        },{
            label:'审核状态',
            prop:'audtTagOk',
            custom:true
        },
    ],
    searchcolumns:[]
}

/**
 * 转单配置
 */
export const transMcOption = {
    showPage:true,//展示分页器
    tHeight:650,
    showMenu:true,//展示右侧操作列
    stripe:true,
    columns:[
        {
            label:'区域',
            prop:'areaName'
        },{
            label:'小区名称',
            prop:'parkName'
        },{
            label:'电梯名称',
            prop:'liftName'
        },{
            label:'电梯编码',
            prop:'code'
        },{
            label:'维保类型',
            prop:'tmpl'
        },{
            label:'维保时间',
            prop:'dtm',
        },{
            label:'原维保员',
            prop:'rcMcNames',
        },{
            label:'现维保员',
            prop:'nowRcMcNames',
        },{
            label:'转单时间',
            prop:'createTime',
        },
        
    ],
    searchcolumns:[
        {
            label:'状态',
            prop:'type',
            type:'select',
            noclearable:true,
            column:[
                {
                    label:'未完成',
                    value:0
                },{
                    label:'已完成',
                    value:1
                },
            ]
        },
        {
            label:'小区名称/备注',
            prop:'parkName'
        },{
            label:'电梯名称/备注',
            prop:'liftName'
        },{
            label:'转单时间',
            prop:'timer',
            searchType:'datetimerange',
        },{
            label:'维保员',
            prop:'mcUidName'
        },
    ]
}
/**
 * 按需维保申请配置项
 */
export const mcNeedViewSheet = {
    showMenu:false,//展示右侧操作列
    stripe:true,
    mtHeight:300,
    dialogTable:true,
    columns:[
        {
            label:'小区名称',
            prop:"parkName",
            custom:true,
        },{
            label:'梯号',
            prop:"liftName",
            custom:true
        },
        {
            label:'所在区域',
            prop:"areaName",
        },
        {
            label:'注册代码',
            prop:"sn",
        },
        {
            label:'电梯编码',
            prop:"code",
        },{
            label:'下次维保时间',
            prop:"nextMc",
            custom:true
        },{
            label:'物业单位',
            prop:"pmCorpName",
        },{
            label:'维保单位',
            prop:"mcCorpName",
        },{
            label:'电梯年限',
            prop:'yearLimit'
        },{
            label:'是否公共区域',
            prop:'pubArea'
        },
        // {
        //     label:'是否有物联网终端',
        //     prop:'areaName'
        // },{
        //     label:'当前维保方式',
        //     prop:'areaName'
        // },{
        //     label:'建议周期',
        //     prop:'areaName'
        // },{
        //     label:'选择周期',
        //     prop:'areaName'
        // }
    ],
    searchcolumns:[]
}
/**
 * 按需维保申请记录
 */
 export const mcNeedApplyRep = {
    showMenu:true,//展示右侧操作列
    stripe:true,
    showPage:true,//展示分页器
    tHeight:650,
    menuWidth:80,
    columns:[
        {
            label:'申请单位',
            prop:"corpName",
        },{
            label:'申请时间',
            prop:"dtm",
        },{
            label:'电梯数量',
            prop:"liftNum",
        },{
            label:'申请资料附件',
            prop:"attachNum",
        }
    ],
    searchcolumns:[
        {
            label:'申请单位',
            prop:"corpName",
        },{
            label:'申请时间',
            prop:"dtm",
            searchType:'datetimerange',
        },
    ]
 }
 /**
  * 开通按需维保上面表格配置
  */
  export const mcNeedModeSysOptionTop ={
    tHeight:320,
    showMenu:true,//展示右侧操作列
    dialogTable:true,
    stripe:true,
    menuWidth:120,
    basicBorder:true,
    // selection:true,
    columns:[
        {
            label:'类型',
            prop:'mcType',
            custom:true,
        },{
            label:'小区名称',
            prop:'parkName'
        },{
            label:'梯号',
            prop:'liftName'
        },{
            label:'区域',
            prop:'areaName'
        },{
            label:'注册代码',
            prop:'sn'
        },{
            label:'维保人',
            prop:'rcMcMan'
        },
    ],
    searchcolumns:[]
  } 
  export const mcNeedModeSysOptionFoot ={
    tHeight:320,
    // showMenu:true,//展示右侧操作列
    dialogTable:true,
    stripe:true,
    menuWidth:120,
    basicBorder:true,
    // selection:true,
    columns:[
        {
            label:'类型',
            prop:'mcType',
            custom:true,
        },{
            label:'小区名称',
            prop:'parkName'
        },{
            label:'梯号',
            prop:'devName'
        },{
            label:'区域',
            prop:'areaName'
        },{
            label:'注册代码',
            prop:'sn'
        },{
            label:'维保人',
            prop:'rcMcMan'
        },
    ],
    searchcolumns:[]
  } 
/**
 * 开通按需维保新增表格配置
 */
export const mcNeedModeSysOptionAdd = {
    showMenu:false,
    showPage:true,//展示分页器
    selection:true,
    stripe:true,
    defShowSearch:true,
    // basicBorder:true,
    columns:[
        {
            label:'已设置未生效',
            prop:'startDtm',
            custom:true,
        },{
            label:'维保类型',
            prop:'mcType',
            custom:true,
        },{
            label:'下次维保时间',
            prop:'nextMc',
        },{
            label:'小区名称',
            prop:'parkName',
            custom:true,
        },{
            label:'电梯编码',
            prop:'code'
        },{
            label:'梯号',
            prop:'liftName'
        },{
            label:'区域',
            prop:'areaName'
        },{
            label:'注册代码',
            prop:'sn'
        },{
            label:'物业单位',
            prop:'pmCorpName'
        },
    ],
    searchcolumns:[
        {
            label:'注册代码/电梯编号/小区名称',
            prop:"key",
            searchcustom:true
        },{
            label:'',
            prop:"areaId",
            type:'select',
            column:[],
            dicUrl:'getRoleCitys',
            getType:'test',
            getParams:{
                city:53
            },
            propformat:{
                label:'name',
                value:'id'
            },
            placeholder:'选择区县'
        },{
            label:'维保类型',
            prop:"need",
            type:'select',
            column:[
                {
                    label:'按需维保',
                    value:1
                },
                {
                    label:'定期维保',
                    value:2
                }
            ] 
        },{
            label:' ',
            prop:"isSet",
            type:'checkbox',
            text:'全部',
            trueLabel:"null", 
        },{
            label:' ',
            prop:"isSet",
            type:'checkbox',
            text:'只看未设置电梯',
            trueLabel:"0", 
        },{
            label:' ',
            prop:"isSet",
            type:'checkbox',
            trueLabel:"1", 
            text:'只看已设置电梯'
        },
    ]
}

/**
 * 开通按需维保外层表格配置
 */
 export const mcNeedliftOption = {
    addShow:false,//展示顶部操作栏
    showMenu:true,//展示右侧操作列
    showPage:true,//展示分页器
    selection:true,
    // showDraw:true,
    menuWidth:120,
    // mtHeight:600,
    columns:[
        {
            label:'区域',
            prop:'areaName'
        },{
            label:'小区名称',
            prop:'parkName',
            custom:true
        },{
            label:'电梯',
            prop:'devName',
            custom:true
        },{
            label:'电梯编码',
            prop:'liftCode'
        },{
            label:'注册代码',
            prop:'sn'
        },{
            label:'维保员',
            prop:'rcMcMan',
        },{
            label:'维保员电话',
            prop:'rcMcPhone',
        },{
            label:'下次维保时间',
            prop:'nextMc'
        },{
            label:'维保间隔（天）',
            prop:'cycle'
        },
    ],
    searchcolumns:[
        {
            label:'小区名称',
            prop:"parkName",
        },{
            label:'下次维保时间',
            prop:'timer',
            searchType:'daterange',
            valueFormat:'yyyy-MM-dd',
            // searchcustom:true,
            pickerOptions:{
                disabledDate(time) {//禁用当前日期之前的日期
                    var t = new Date().getTime()
                    var m = time<new Date(t- (24*60*60*1000))
                    var m1 = time>new Date(t+(24*60*60*1000)*14)
                    return m1||m;
                },
            },
        },{
            label:'区县',
            prop:"areaId",
            type:'select',
            column:[],
            dicUrl:'getRoleCitys',
            getType:'test',
            getParams:{
                city:53
            },
            propformat:{
                label:'name',
                value:'id'
            },
            placeholder:'选择区县'
        },
        // {
        //     label:'物业单位',
        //     prop:'pmCorpName'
        // },
        {
            label:'电梯编码',
            prop:'liftCode'
        },{
            label:'注册代码',
            prop:'sn'
        }
    ]
}
export const stopLiftOption = {
    selection:true,
    showMenu:true,//展示右侧操作列
    showPage:true,//展示分页器
    menuWidth:160,
    columns:[
        {
            label:'区县',
            prop:'areaName'
        },{
            label:'小区',
            prop:'parkName'
        },{
            label:'梯号',
            prop:'liftName'
        },{
            label:'使用单位',
            prop:'pmCorpName'
        },{
            label:'电梯编码',
            prop:'code'
        },{
            label:'注册代码',
            prop:'sn'
        },{
            label:'上次维保时间',
            prop:'lastMc'
        },{
            label:'停梯时间',
            prop:'stopTime'
        }
    ],
    searchcolumns:[
        {
            label:'',
            prop:"areaId",
            type:'select',
            column:[],
            dicUrl:'getRoleCitys',
            getType:'test',
            getParams:{
                city:53
            },
            propformat:{
                label:'name',
                value:'id'
            },
            placeholder:'选择区县'
        },{
            label:'',
            prop:"isStopMc",
            searchcustom:true,
        },{
            label:'',
            prop:"pmCorp",
            placeholder:'使用单位'
        },{
            label:'',
            prop:"keyWord",
            searchcustom:true,
            placeholder:'注册代码/电梯编号/小区名称'
        },
    ]
}
export const stopLiftSysOption ={
    showMenu:true,//展示右侧操作列
    menuWidth:100,
    tHeight:630,
    dialogTable:true,
    columns:[
        {
            label:'区县',
            prop:'areaName'
        },{
            label:'小区',
            prop:'parkName'
        },{
            label:'梯号',
            prop:'liftName'
        },{
            label:'使用单位',
            prop:'pmCorpName'
        },{
            label:'电梯编码',
            prop:'code'
        },{
            label:'注册代码',
            prop:'sn'
        },{
            label:'上次维保时间',
            prop:'lastMc'
        },{
            label:'停梯时间',
            prop:'stopTime'
        }
    ],
    searchcolumns:[
    ]
}


/**
 * 亚德延期查询
 */
 export const mcDelayYadeOption = {
    showMenu:true,//展示右侧操作列
    showPage:true,//展示分页器
    selection:true,
    stripe:true,
    tHeight:650,
    menuWidth:140,
    columns:[
        {
            label:'区域',
            prop:'areaName'
        },{
            label:'小区名称',
            prop:'parkName',
            custom:true
        },{
            label:'电梯',
            prop:'devName',
            custom:true
        },{
            label:'电梯编码',
            prop:'liftCode'
        },{
            label:'注册代码',
            prop:'sn'
        },{
            label:'维保单位',
            prop:'mcCorpName',
        },{
            label:'使用单位',
            prop:'pmCorpName',
        },
        // {
        //     label:'状态',
        //     prop:"isStay",
        //     type:'select',
        //     column:[
        //         {label:'可恢复排期',value:true},
        //         {label:'无法恢复（排期数据缺失）',value:false},
        //     ]
        // },
    ],
    searchcolumns:[
        // {
        //     label:'状态',
        //     prop:"isStay",
        //     type:'select',
        //     column:[
        //         {label:'可恢复排期',value:true},
        //         {label:'无法恢复（排期数据缺失）',value:false},
        //     ]
        // },
        {
            label:'小区',
            prop:'parkName',
        },{
            label:'地区',
            prop:'areaId',
            type:'select',
            column:[],
            dicUrl:'areaByPid',
            // dicKey:'lift.type'
            getType:'test',
            getParams:{
                pid:53
            },
            propformat:{
                label:'name',
                value:'id'
            }
        },{
            label:'电梯编码',
            prop:'liftCode'
        },{
            label:'注册代码',
            prop:'sn'
        }
    ]
}