<template>
    <div>
        <Table :tableData="tableData" :tableOptions="tableOption">
            <template v-slot:leval="row">
                <p class="t_red">{{row.data.row.leval}}</p>
            </template>
            <template v-slot:right_scope="data" >
                <el-button type="primary" plain @click="getInfo(data)" size="small">查看</el-button>
            </template>
        </Table>
        <!-- 侧边抽屉 -->
        <el-drawer
            title="工单详情（N1858366233）"
            :visible.sync="drawer"
            append-to-body>
            <div class="alarm_info">
                <div class="alarm_tabel_item">
                    <ul class="item_info_list flex f_warp">
                        <li class="item">
                            级别：一级
                        </li>
                        <li class="item">
                            报障时间：2021-04-29 23:00:00
                        </li>
                        <li class="item">
                            类型：困人
                        </li>
                        <li class="item">
                            报障位置：两江新区/民心佳园.24-3
                        </li>
                        <li class="item">
                            告警类型：运行告警
                        </li>
                        <li class="item">
                            报障来源：监控中心
                        </li>
                        <li class="item">
                            人员标签：普通
                        </li>
                        <li class="item">
                            接警人员：张三
                        </li>
                        <li class="item">
                            乘客情况：稳定
                        </li>
                        <li class="item">
                            接警人员：张三
                        </li>
                    </ul>
                </div>
                <div class="audio_list">
                    <div class="draw_title flex">
                        <i></i>
                        <span>电话记录</span>
                    </div>
                    <ul class="list">
                        <li class="item flex f_between">
                            <label>4-29 00:23</label>
                            <div class="audio_mode">
                                <Audiocs></Audiocs>
                            </div>
                        </li>
                        <li class="item flex f_between">
                            <label>4-29 00:23</label>
                            <div class="audio_mode">
                                <Audiocs></Audiocs>
                            </div>
                        </li>
                        <li class="item flex f_between">
                            <label>4-29 00:23</label>
                            <div class="audio_mode">
                                <Audiocs></Audiocs>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="alarm_steps">
                    <div class="draw_title flex">
                        <i></i>
                        <span>工单流程</span>
                    </div>
                    <ul class="step_list">
                        <li class="item flex f_r f_start">
                            <label>04-29 23:52</label>
                            <p><span>界面手动派发工单：N1858366233</span><em>张三</em></p>
                        </li>
                        <li class="item flex f_r f_start">
                            <label>04-29 23:52</label>
                            <p><span>界面手动派发工单：N1858366233</span><em>张三</em></p>
                        </li>
                        <li class="item flex f_r f_start">
                            <label>04-29 23:52</label>
                            <p><span>界面手动派发工单：N1858366233</span><em>张三</em></p>
                        </li>
                        <li class="item flex f_r f_start final">
                            <label>04-29 23:52</label>
                            <p><span>完成</span><em>张三</em></p>
                        </li>
                    </ul>
                </div>
            </div>
        </el-drawer>
    </div>
</template>
<script>
import {alarmOption} from "@/collocat/options.js"
import Audiocs from "@/components/audiomode.vue"
export default {
    name:'alarm',
    components:{
        Audiocs
    },
    data(){
        return{
            tableOption:alarmOption,
            tableData: [
                {
                    leval:"1",   
                    type:1,
                    addr:'加新花园/7-1-2#',
                    navget:'终端',
                    way:1,
                    event:'终端离线',
                    site:'信发和对讲离线',
                    start:'4-14 16:44',
                    end:'4-14 16:44',
                    alarm:'系统'  	              	                     	                      	
                },
                {
                    leval:"4", 
                    type:1,
                    addr:'加新花园/7-1-2#',
                    navget:'终端',
                    way:1,
                    event:'终端离线',
                    site:'信发和对讲离线',
                    start:'4-14 16:44',
                    end:'4-14 16:44',
                    alarm:'系统'  		          	              	                     	                      	
                },
            ],
            drawer:false,
        }
    },
    methods:{
        getInfo(row){
            console.log(row);
            this.$set(this,'drawer',true)
        },
    }
}
</script>
<style lang="less" scoped>
.alarm_info{
    .alarm_tabel_item{
        padding: .12rem .2rem;
        border-bottom: 1px solid #dddddd;
        .item_info_list{
            .item{
                width: 50%;
                font-size: .16rem;
                font-weight: 500;
                color: #333333;
                line-height: .285rem;
            }
        }
    }
}
.draw_title{
    padding: .26rem .2rem .1rem;
    i{
        width: .04rem;
        height: .14rem;
        background: #2680EB;
        box-shadow: 0px 0px 1px #2680EB;
        border-radius: .2rem;
        margin-right: .08rem;
    }
    span{
        font-size: .18rem;
        font-weight: bold;
        color: #333333;
    }
}
.audio_list{
    .list{
        padding: 0 .2rem;
        .item{
            margin-bottom: .24rem;
            label{
                width: 1.66rem;
                font-size: .16rem;
                font-weight: 500;
                color: #333333;
            }
            .audio_mode{
                width: calc(100% - 1.66rem);
            }
            &:last-of-type{
                margin-bottom: 0;
            }
        }
    }
}
.alarm_steps{
    .step_list{
        padding: 0 .26rem;
        .item{
            line-height: .41rem;
            font-size: .16rem;
            color: #333333;
            font-weight: 500;
            padding: .2rem 0;
            label{
                width: 1.52rem;
            }
            p{
                width: calc(100% - 1.52rem);
                padding: 0 .2rem;
                background-color: rgba(2,120,241,.07);
                border-radius: .06rem;
                em{
                    color: #999999;
                    margin-left: .15rem;
                }
            }
            &::before{
                content: '';
                display: inline-block;
                width: 0;
                height: 100%;
                border-right: 1px solid #CCE4FC;
                position: absolute;
                top: .42rem;
                left: 1.23rem;
            }
            &::after{
                content: '';
                display: inline-block;
                width: .1rem;
                height: .1rem;
                border-radius: .1rem;
                background-color: #CCE4FC;
                position: absolute;
                top: 0.37rem;
                left: 1.18rem;
            }
            &:last-of-type{
                 &::before{
                     content: none;
                 }
            }
            &.final{
                p{
                    span{
                        color: #0278F1;
                    }
                }
                &::after{
                    background-color: #0278F1;
                }
                &::before{
                    border-color: #0278F1;
                }
            }
        }
    }
}
</style>