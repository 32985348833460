<template>
    <div>
        <Table :tableData="tableData" :tableOptions="tableOption">
            <template v-slot:leval="row">
                <p class="t_red">{{row.data.row.leval}}</p>
            </template>
            <template v-slot:right_scope="data" >
                <el-button type="primary" plain @click="getInfo(data)" size="small">查看</el-button>
            </template>
        </Table>
        <el-dialog
            title="音频播放"
            :visible.sync="dialogVisible"
            width="30%"
            append-to-body
            :close-on-click-modal="false">
            <Audiocs></Audiocs>
        </el-dialog>
        <el-dialog
            title="视频播放"
            :visible.sync="dialogVisible1"
            width="896"
            append-to-body
            :close-on-click-modal="false">
            <el-row :gutter="10">
                <el-col :span="12">
                    <p class="f-box t_99">
                        永川区-豪都佳苑-3单元
                    </p>
                    <div class="video_box">
                        <video src="" controls="controls">
                            您的浏览器不支持 video 标签。
                        </video>
                    </div>
                </el-col>
                <el-col :span="12">
                    <p class="f-box t_99">
                        客服：陈毅
                    </p>
                    <div class="video_box">
                        <video src="https://www.w3school.com.cn/i/movie.ogg" controls="controls">
                            您的浏览器不支持 video 标签。
                        </video>
                    </div>
                </el-col>
            </el-row>
            <span slot="footer" class="dialog-footer f-box">
                <el-button size="mini">贴标签</el-button>
                <el-button type="primary" @click="dialogVisible1 = false" size="mini">关 闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import {alarmOption} from "@/collocat/options.js"
import Audiocs from "@/components/audiomode.vue"
export default {
    name:'callhistory',
    components:{
        Audiocs
    },
    data(){
        return{
            tableOption:alarmOption,
            dialogVisible:false,
            dialogVisible1:false,
            tableData: [
                {
                    leval:"1",   
                    type:1,
                    addr:'加新花园/7-1-2#',
                    navget:'终端',
                    way:1,
                    event:'终端离线',
                    site:'信发和对讲离线',
                    start:'4-14 16:44',
                    end:'4-14 16:44',
                    alarm:'系统'  	              	                     	                      	
                },
                {
                    leval:"4", 
                    type:1,
                    addr:'加新花园/7-1-2#',
                    navget:'终端',
                    way:1,
                    event:'终端离线',
                    site:'信发和对讲离线',
                    start:'4-14 16:44',
                    end:'4-14 16:44',
                    alarm:'系统'  		          	              	                     	                      	
                },
            ],
        }
    },
    methods:{
        getInfo(row){
            this.$set(this,'dialogVisible',true)
        }
    }
}
</script>
<style lang="less" scoped>
.video_box{
    width: 100%;
    height: 2.84rem;
    background-color: #000000;
    border-radius: .06rem;
    video{
        width: 100%;
        height: 100%;
        background-color: #000000;
        border-radius: .06rem;
    }
}
</style>