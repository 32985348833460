export const testOption = {
    addShow:true,//展示顶部操作栏
    showMenu:true,//展示右侧操作列
    editBtn:true,
    delBtn:true,
    showPage:true,//展示分页器
    menuWidth:250,
    columns:[
        {
            prop:"name",
            label:"姓名",
            width:"180",
        },
        {
            prop:"date",
            label:"日期",
            width:"180",
            type:'date',
            searchType:'daterange',
            formatValue:'YYYY-MM-dd',
            format:true
        },
        {
            prop:"address",
            label:"地址",
        }
    ],
    rules:{
        // name: [
        //     { required: true, message: '请输入名称', trigger: 'blur' },
        //     { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        // ],
        // address: [
        //     { required: true, message: '请输入地址', trigger: 'blur' },
        // ],
    },
    searchcolumns:[
        {
            prop:"name",
            label:"姓名",
        },
        {
            prop:"date",
            label:"日期",
        }
    ]
}
export const gailanOption = {
    addShow:false,//展示顶部操作栏
    showMenu:false,//展示右侧操作列
    showPage:false,//展示分页器
    columns:[
        {
            label:'级别',
            prop:"lavel",
        },
        {
            label:'工单',
            prop:"no",
        },
        {
            label:'区域',
            prop:"are",
            custom:true,
        },
        {
            label:'小区',
            prop:"village",
        },
        {
            label:'事件名称',
            prop:"name",
        },
        {
            label:'报警时间',
            prop:"date",
            formatValue:'MM-dd hh-mm-ss',
            //format:true
        },
    ],
    rules:{},
}
export const liftmaintainOption = {
    addShow:false,//展示顶部操作栏
    showMenu:false,//展示右侧操作列
    showPage:true,//展示分页器
    selection:true,
    columns:[
        {
            label:'区域',
            prop:'are',
        },
        {
            label:'小区',
            prop:'village',
        },
        {
            label:'电梯',
            prop:'lift',
        },
        {
            label:'维保类型',
            prop:'type',
            type:'select',
            column:[
                {
                    label:'按需维保',
                    value:1
                },
                {
                    label:'定期维保',
                    value:2
                },
            ]
        },
        {
            label:'电梯编码',
            prop:'liftCode',
        },
        {
            label:'告警次数',
            prop:'num',
            custom:true,
        },
        {
            label:'重大事件',
            prop:'error',
            custom:true,
        },
        {
            label:'终端',
            prop:'navget',
            type:'select',
            column:[
                {
                    label:'无',
                    value:0
                },
                {
                    label:'已安装',
                    value:1
                },
            ]
        },
        {
            label:'上次维保时间',
            prop:'date'
        },
        {
            label:'维保单位',
            prop:'name'
        },
        {
            label:'使用单位',
            prop:'usename'
        },
    ],
    searchcolumns:[
        {
            label:'小区',
            prop:'village',
            searchcustom:true,
        },
        {
            label:'维保类型',
            prop:'type',
            type:'select',
            // searchcustom:true,
            column:[
                {
                    label:'按需维保',
                    value:1
                },
                {
                    label:'定期维保',
                    value:2
                },
            ]
        },
        {
            label:'终端',
            prop:'navget',
            type:'select',
            column:[
                {
                    label:'无',
                    value:0
                },
                {
                    label:'已安装',
                    value:1
                },
            ]
        }
    ]
}
export const alarmOption = {
    addShow:false,//展示顶部操作栏
    showMenu:true,//展示右侧操作列
    showPage:true,//展示分页器
    selection:false,
    columns:[
        {
            label:'告警级别',
            prop:'leval',
            type:'select',
            custom:true,
            column:[
                {
                    label:'一级',
                    value:1
                },
                {
                    label:'二级',
                    value:2
                },
                {
                    label:'三级',
                    value:3
                },
                {
                    label:'四级',
                    value:4
                },
            ]
        },
        {
            label:'告警类型',
            prop:'type',
            type:'select',
            column:[
                {
                    label:'运行',
                    value:1
                },{
                    label:'信发告警',
                    value:2
                },{
                    label:'困人',
                    value:3
                },
            ]
        },
        {
            label:'位置',
            prop:'addr',
        },
        {
            label:'设备',
            prop:'navget',
        },
        {
            label:'方式',
            prop:'way',
            type:'select',
            column:[
                {
                    label:'自动',
                    value:1
                },{
                    label:'手动触发',
                    value:2
                }
            ]
        },
        {
            label:'事件',
            prop:'event',
        },
        {
            label:'现场',
            prop:'site',
            width:'300'
        },
        {
            label:'发生时间',
            prop:'start',
        },
        {
            label:'关闭时间',
            prop:'end',
        },
        {
            label:'接警',
            prop:'alarm',
        },
    ],
    searchcolumns:[
        {
            label:'告警级别',
            prop:'leval',
            type:'select',
            column:[
                {
                    label:'一级',
                    value:1
                },
                {
                    label:'二级',
                    value:2
                },
                {
                    label:'三级',
                    value:3
                },
                {
                    label:'四级',
                    value:4
                },
            ]
        },
    ]
}
export const mtaDrawOptin = {
    addShow:false,//展示顶部操作栏
    showMenu:false,//展示右侧操作列
    showPage:false,//展示分页器
    showindex:true,
    columns:[
        {
            label:'维保内容和要求',
            prop:"main",
            minWidth:'6',
            custom:true,
        },
        {
            label:'备注',
            prop:"remark",
            minWidth:'5'
        },
        {
            label:'结果',
            prop:"final",
            minWidth:'2',
            custom:true,
        }
    ]
}
/**
 * 角色管理列表配置
 */
export const roleOption = {
    addShow:false,//展示顶部操作栏
    showMenu:true,//展示右侧操作列
    showPage:true,//展示分页器
    showindex:false,
    stripe:true,
    mtHeight:680,
    menuWidth:380,
    tablePanel:true,
    customShearchClass:true,
    hasFilters:true,
    showRowClassName:true,
    RowClassrender:(row)=>{
        if(row.tagDisable){
            return 'danger-row';
        }
    },
    columns:[
        {
            label:'状态',
            prop:"status",
            // minWidth:'6',
            custom:true,
            filters:[{ text: '禁用', value: true }, { text: '正常', value: false }],
            filterMethod:(value, row, column)=>{  
                //筛选选项的value，一行row对象,column一列的属性值
                const property = column['property'];    // column['property']值为属性名·
                // console.log(JSON.stringify(column));
                //筛选数据中[{campus: '',..... }]这种类型数据
                if(Array.isArray(row[property])){   
                    //行中该属性是数组
                    var  arr = false;
                    for(var i in row[property]){
                    //每次的arr为上一次和这一次结果的或，效果就是如果数据出现新老校区都有的情况，筛选到时候新老校区都会显示这条数据
                        arr = (row[property][i].campus === value)||arr;
                    }
                    return arr;
                }
                else if(row[property] instanceof Object){
                    //属性值是对象
                        if(row[property].isInstituteRc!=undefined)
                            return row[property].isInstituteRc  === value;
                    }
                else{ 
                    //普通属性值
                    return row[property] === value;
                }
            }
        },{
            label:'角色',
            prop:"name",
        },{
            label:'描述',
            prop:"remark",
        },{
            label:'用户数',
            prop:"authNum",
        },{
            label:'APP菜单数',
            prop:"appMenuNum",
        },{
            label:'平台菜单数',
            prop:"pfMenuNum",
        },
    ],
    searchcolumns:[
        // {
        //     label:'角色名称',
        //     prop:'name',
        // },
    ]
}
export const villageOption = {
    addShow:false,//展示顶部操作栏
    showMenu:true,//展示右侧操作列
    showPage:true,//展示分页器
    showindex:false,
    showDraw:true,
    menuWidth:240,
    tHeight:700,
    columns:[
        {
            label:'小区名称',
            prop:"parkName",
            // minWidth:'6',
            custom:true,
        },{
            label:'所在区域',
            prop:"areaName",
        },{
            label:'街道',
            prop:"road",
        },{
            label:'小区地址',
            prop:"addr",
        },{
            label:'物业单位',
            prop:"corpName",
        },{
            label:'值班电话',
            prop:"linkPhone",
        }
    ],
    searchcolumns:[
        {
            label:'地区',
            prop:"area",
            type:'select',
            // searchcustom:true
            column:[],
            dicUrl:'getAddrList',
            getType:'test',
            getParams:{
                pid:53
            },
            propformat:{
                label:'name',
                value:'id'
            }
        },{
            label:'小区名称',
            prop:'name'
        },{
            label:'物业名称',
            prop:'pmCorpName'
        },
        // {
        //     label:'维保公司',
        //     prop:'mcCorp',
        // },
        {
            label:'街道名称',
            prop:'road',
            searchcustom:true
        },{
            label:' ',
            prop:"onlyRoad",
            type:'checkbox',
            text:'只显示没有街道的小区',
            searchcustom:true
            // placeholder:'没有街道的小区显示与否',
            // type:'select',
            // column:[
            //     {
            //         label:'只显示没有街道的小区',
            //         value:true
            //     },{
            //         label:'不显示没有街道的小区',
            //         value:false
            //     },
            // ],
        },
    ]
}
/**
 * 用户管理配置
 */
export const usermngOption = {
    showMenu:true,//展示右侧操作列
    showPage:true,//展示分页器
    showSearch:true,
    stripe:true,
    // mtHeight:680,
    menuWidth:320,
    tablePanel:true,
    customShearchClass:true,
    showRowClassName:true,
    RowClassrender:(row)=>{
        if(row.status == 2){
            return 'danger-row';
        }
    },
    columns:[
        {
            label:'员工姓名',
            prop:"name",
        },{
            label:'联系电话',
            prop:"loginName",
        },{
            label:'角色名称',
            prop:"role",
            custom:true
        }
    ],
    searchcolumns:[
        {
            label:'员工姓名/员工电话',
            prop:"keyWord",
        }
    ]
}