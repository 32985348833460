<template>
    <div class="info_list plr_20 pbt_20">
        <div class="info_item f_r">
            <label class="if-box label">
                上次维保数据
            </label>
            <div class="flex f_between item_info">
                <div class="info">
                    <p>有困人</p>
                    <p class="t_99">2012.01.23</p>
                </div>
                <label class="if-box">
                    <i class="el-icon-more"></i>
                </label>
            </div>
        </div>
        <div class="info_item f_r">
            <label class="if-box label">
                困人数据
            </label>
            <div class="flex f_between item_info">
                <div class="info">
                    <p>有困人</p>
                    <p class="t_99">2012.01.23</p>
                </div>
                <label class="if-box">
                    <i class="el-icon-more"></i>
                </label>
            </div>
        </div>
        <div class="info_item f_r">
            <label class="if-box label">
                告警数据
            </label>
            <div class="flex f_between item_info">
                <div class="info">
                    <p>有困人</p>
                    <p class="t_99">2012.01.23</p>
                </div>
                <label class="if-box">
                    <i class="el-icon-more"></i>
                </label>
            </div>
        </div>
        <div class="info_item f_r">
            <label class="if-box label">
                投诉数据
            </label>
            <div class="flex f_between item_info">
                <div class="info">
                    <p>有困人</p>
                    <p class="t_99">2012.01.23</p>
                </div>
                <label class="if-box">
                    <i class="el-icon-more"></i>
                </label>
            </div>
        </div>
        <div class="info_item f_r">
            <label class="if-box label">
                建议维保项
            </label>
            <div class="flex f_between item_info">
                <div class="info">
                    <p>有困人</p>
                    <p class="t_99">2012.01.23</p>
                </div>
                <label class="if-box">
                    <i class="el-icon-more"></i>
                </label>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'stepsone'
}
</script>
<style lang="less" scoped>
.info_list{
    height: 4.32rem;
    overflow-y: auto;
    .info_item{
        padding-left: 1.5rem;
        margin-bottom: .1rem;
        .label{
            width: 1.4rem;
            height: .8rem;
            position: absolute;
            top: 0;
            left: 0;
            background: #FBFCFE;
            border: 1px solid #E2EEFB;
            border-radius: .06rem;
            font-size: .16rem;
            font-weight: bold;
            color: #333333;
        }
        .item_info{
            width: 100%;
            height: .8rem;
            background: #FEFEFE;
            border: 1px solid #F2F1F1;
            opacity: 1;
            border-radius: 6px;
            padding: .18rem .15rem;
            .info{
                p{
                    font-size: .14rem;
                    font-weight: 500;
                    // line-height: .46rem;
                    color: #333333; 
                    margin-bottom: .03rem; 
                    &.t_99{
                        font-size: .12rem;
                        font-weight: 500;
                    }
                    &:last-of-type{
                        margin-bottom: 0;
                    }
                }
            }
            label{
                font-size: .2rem;
                transform: rotate(90deg);
            }
        }
        &:last-of-type{
            margin-bottom: 0;
        }
    }
}
</style>