<template>
    <div>
        <el-drawer
            title="急修工单"
            :visible.sync="drawer"
            :direction="direction">
            <div class="choutiForm">
                <el-form :model="form" label-width="100px">
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <el-form-item label="客户名称">
                                <el-input></el-input>
                            </el-form-item>
                            <el-form-item label="项目名称">
                                <el-input></el-input>
                            </el-form-item>
                            <el-form-item label="电梯位置">
                                <el-input></el-input>
                            </el-form-item>
                            <el-form-item label="技术人员">
                                <el-input></el-input>
                            </el-form-item>
                            <el-form-item label="接报时间">
                                <el-input></el-input>
                            </el-form-item>
                            <el-form-item label="离开时间">
                                <el-input></el-input>
                            </el-form-item>
                            <el-form-item label="到达时电梯是否运行">
                                <el-input></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="电梯型号">
                                <el-input></el-input>
                            </el-form-item>
                            <el-form-item label="电梯梯号">
                                <el-input></el-input>
                            </el-form-item>
                            <el-form-item label="保养站">
                                <el-input></el-input>
                            </el-form-item>
                            <el-form-item label="项目编号">
                                <el-input></el-input>
                            </el-form-item>
                            <el-form-item label="到达时间">
                                <el-input></el-input>
                            </el-form-item>
                            <el-form-item>
                            </el-form-item>
                            <el-form-item label="离开时电梯是否运行">
                                <el-input></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col>
                            <el-form-item label="客户报告急修详情">
                                <el-input type="textarea" :rows="4" placeholder="电梯非平层关门，需尽快更换零部件"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-divider></el-divider>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="所用材料">
                                <el-input></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="价格">
                                <el-input></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col>
                            <el-form-item label="调查、解决问题行动、建议及后续行动详情">
                                <el-input type="textarea" :rows="4" placeholder="电梯非平层关门，需尽快更换零部件"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col>
                            <el-form-item label="客户建议及要求">
                                <el-input type="textarea" :rows="4" placeholder="电梯非平层关门，需尽快更换零部件"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="客户确认">
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="技术员确认">
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
        </el-drawer>
    </div>
</template>
<script>
export default {
    name:'chouti',
    data(){
        return{
            drawer:true,
            direction: 'rtl',
            form:{}
        }
    }
}
</script>
<style lang="less" scoped>
.choutiForm{
    padding: 0 .1rem;
    /deep/.el-form{
        .el-input__inner,.el-textarea__inner{
            background-color: #FBFCFE;
            border-color: #E2EEFB;
        }
    }
}
</style>