<template>
    <div>
        <el-dialog title="重庆市/江北区/保利中心B3-A" 
            :visible.sync="dialogVisible"
            width="680">
            <div class="steps flex f_between">
                <div class="steps_item flex_tel f_center f_r step">
                    <div class="steps_item_box if-box">
                        <label class="if-box"><i class="if-box">01</i></label>
                        <p>电梯信息</p>
                    </div>
                </div>
                <div class="steps_item flex_tel f_center f_r">
                    <div class="steps_item_box if-box">
                        <label class="if-box"><i class="if-box">02</i></label>
                        <p>选择维保项</p>
                    </div>
                </div>
                <div class="steps_item flex_tel f_center f_r">
                    <div class="steps_item_box if-box">
                        <label class="if-box"><i class="if-box">03</i></label>
                        <p>选择维保人员</p>
                    </div>
                </div>
                <div class="steps_item flex_tel f_center f_r">
                    <div class="steps_item_box if-box">
                        <label class="if-box"><i class="if-box">04</i></label>
                        <p>选择时间</p>
                    </div>
                </div>
            </div>
            <div class="step_content">
                <template v-if="stepsIndex == 1">
                    <stepsone></stepsone>
                </template>
                <template v-else-if="stepsIndex == 2">
                    <stepstwo></stepstwo>
                </template>
                <template v-else-if="stepsIndex == 3">
                    <div class="mtcper">
                        <el-transfer
                            style="text-align: left; display: inline-block"
                            v-model="trsvalue"
                            filterable
                            :render-content="renderFunc"
                            :titles="['所有人员', '已选人员']"
                            :format="{
                                noChecked: '${total}',
                                hasChecked: '${checked}/${total}'
                            }"
                            @change="handleChange"
                            :data="trsdata">
                        </el-transfer>
                    </div>
                </template>
                <template v-else-if="stepsIndex == 4">
                    <div class="f-box timeSpace">
                        <el-form :model="stepForm" label-width="40%">
                            <el-form-item label="选择完成时间">
                                <el-date-picker
                                    v-model="stepForm.time"
                                    prefix-icon="el-icon-date"
                                    type="datetime"
                                    placeholder="选择日期时间">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item label="最长维保间隔">
                                <el-select v-model="stepForm.space" placeholder="请选择">
                                    <el-option
                                        v-for="item in spaceList"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-form>
                    </div>
                </template>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" plain size="small">上一步</el-button>
                <el-button type="primary" size="small">下一步</el-button>
                <el-button type="primary" size="small">派单</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import {stepsone,stepstwo} from "./index.js"
export default {
    name:"weibao",
    components:{
        stepsone,
        stepstwo
    },
    data(){
        return{
            dialogVisible:false,
            stepsIndex:4,
            trsdata:[
                {
                    key: 0,
                    label: "张三 （18888888888）",
                    sublabel:'SPT维保公司',
                    disabled: false
                },
                {
                    key: 1,
                    label: "李四 （18888888888）",
                    sublabel:'SPT维保公司',
                    disabled: false
                },
                {
                    key: 2,
                    label: "王五 （18888888888）",
                    sublabel:'SPT维保公司',
                    disabled: false
                },
                {
                    key: 3,
                    label: "赵六 （18888888888）",
                    sublabel:'SPT维保公司',
                    disabled: false
                },
                {
                    key: 4,
                    label: "孙七 （18888888888）",
                    sublabel:'SPT维保公司',
                    disabled: false
                },
            ],
            trsvalue:[],
            renderFunc(h, option) {
                return <div class="transOption"><h5>{ option.label }</h5><p class="t_99">{option.sublabel}</p></div>;
            },
            stepForm:{

            },
            spaceList:[
                {
                    label:'两个月',
                    value:1
                },
                {
                    label:'半年',
                    value:2
                }
            ]
        }
    }
}
</script>
<style lang="less" scoped>
.steps{
    height: .53rem;
    // margin: 0 .5rem;
    .steps_item{
        z-index: 1;
        // &:last-of-type{
        //     background-color: #ffffff;
        // }
        .steps_item_box{
            padding: 0 .05rem;
            background-color: #ffffff;
            z-index: 2;
            label{
                width: .3rem;
                height: .3rem;
                background: rgba(2,120,241,.1);
                border-radius: 50%;
                margin-right: .05rem;
                i{
                    width: .26rem;
                    height: .26rem;
                    background: #FFFFFF;
                    border: 1px solid rgba(46,139,241,.1);
                    border-radius: 50%;
                    font-size: .12rem;
                    font-weight: bold;
                    color: rgba(2,120,241,.5);
                }
            }
            p{
                font-size: .14rem;
                color: rgba(2,120,241,.5);
            }
        }
        &.steps_item.step{
            .steps_item_box{
                label{
                    // background:rgba(2,120,241,1);
                    i{
                        background: #0278F1;
                        border: 1px solid #B0C4F0;
                        color: #ffffff;
                    }
                }
                p{
                    color: #0278F1;
                }
            }
            &::after{
                content: '';
            }
        }
        &::before{
            content: '';
            width: 100%;
            height: 0;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 50%;
            right: 0;
            margin: auto;
            border-bottom: 1px dashed rgba(25,86,211,.2);
            z-index: 0;
            // transform: translateX(-50%);
        }
        &:last-of-type::before{
            content: none !important;
        }
        &::after{
            content: none;
            width: 100%;
            height: 0;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 50%;
            margin: auto;
            border-bottom: 1px solid rgba(25,86,211,1);
            z-index: 1;
            // transform: translateX(-50%);
        }
        &:last-of-type::after{
            content: none !important;
        }
    }
}
/deep/.el-transfer{
    width: 100%;
    position: relative;
    padding-bottom: 46px;
    .el-transfer-panel{
        width: calc(50% - 10px);
        .el-transfer-panel__list{
            .el-transfer-panel__item{
                height: auto;
                margin-bottom: 5px;
                .el-checkbox__label{
                    .transOption{
                        h5{
                            font-size: .16rem;
                            line-height: 30px;
                        }
                        p{
                            font-size: .12rem;
                            line-height: 15px;
                        }
                    }
                }
            }
        }
        &:last-of-type{
            margin-left: 20px;
        }
    }
    .el-transfer__buttons{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        text-align: center;
        .el-transfer__button:first-child{
            margin-bottom: 0;
        }
    }
}
.timeSpace{
    width: 100%;
    min-height: 4rem;
    /deep/.el-form{
        width: 100%;
    }
}
</style>