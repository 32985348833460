<template>
    <div>
        <el-row :gutter="20">
            <el-col :span="15">
                <div class="laps_list">
                    <div class="laps_item panel" :class="{'select':item.select}" v-for="(item,index) in leftList" :key="index">
                        <div class="item_title flex f_between">
                            <div class="if-box txt">
                                <i class="if-box">
                                    <img :src="item.icon"/>
                                </i>
                                <span>{{item.title}}</span>
                            </div>
                            <span class="if-box more" @click="setItem(item,index)">
                                <template v-if="item.select">
                                    <em>收起</em>
                                    <i class="if-box">
                                        <img src="@/assets/images/title_icon_12.png"/>
                                    </i>
                                </template>
                                <template v-else>
                                    <em>展开</em>
                                    <i class="if-box">
                                        <img src="@/assets/images/title_icon_15.png"/>
                                    </i>
                                </template>
                            </span>
                        </div>
                        <div class="item_box">
                            <div class="card-info">
                                <div class="info-item flex" v-for="(ite,ind) in item.list" :key="ind">
                                    <label>{{ite.label}}</label><span>{{ite.value}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>
            <el-col :span="9">
                <div class="laps_list">
                    <div class="laps_item select panel">
                        <div class="item_title flex f_between">
                            <div class="if-box txt">
                                <i class="if-box">
                                    <img src="@/assets/images/title_icon_11.png"/>
                                </i>
                                <span>使用登记信息</span>
                            </div>
                            <span class="if-box more">
                                <label class="if-box">
                                    <el-image 
                                        :src="url" 
                                        :preview-src-list="srcList">
                                    </el-image>
                                </label>
                            </span>
                        </div>
                        <div class="item_box">
                            <div class="card-info">
                                <div class="info-item flex">
                                    <label>设备使用地址</label><span>新溉路与北滨二路交叉口</span>
                                </div>
                                <div class="info-item flex">
                                    <label>使用频率</label><span>18次/天</span>
                                </div>
                                <div class="info-item flex">
                                    <label>使用环境</label><span>住宅</span>
                                </div>
                                <div class="info-item flex">
                                    <label>使用单位名称</label><span>保利物业管理有限公司</span>
                                </div>
                                <div class="info-item flex">
                                    <label>使用单位等级</label><span>1级</span>
                                </div>
                                <div class="info-item flex">
                                    <label>电梯使用状态</label><span>在用</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="laps_item select panel">
                        <div class="item_title flex f_between">
                            <div class="if-box txt">
                                <i class="if-box">
                                    <img src="@/assets/images/title_icon_17.png"/>
                                </i>
                                <span>使用登记信息</span>
                            </div>
                        </div>
                        <div class="item_box">
                            <div class="card-info">
                                <div class="info-item flex">
                                    <label>设备使用地址</label><span>新溉路与北滨二路交叉口</span>
                                </div>
                                <div class="info-item flex">
                                    <label>使用频率</label><span>18次/天</span>
                                </div>
                                <div class="info-item flex">
                                    <label>使用环境</label><span>住宅</span>
                                </div>
                                <div class="info-item flex">
                                    <label>使用单位名称</label><span>保利物业管理有限公司</span>
                                </div>
                                <div class="info-item flex">
                                    <label>使用单位等级</label><span>1级</span>
                                </div>
                                <div class="info-item flex">
                                    <label>电梯使用状态</label><span>在用</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
export default {
    name:'archives',
    data(){
        return{
            url:'',
            srcList:[],
            leftList:[
                {
                    title:'电梯信息',
                    select:false,
                    icon:require('@/assets/images/title_icon_4.png'),
                    list:[{label:'设备使用地址',value:'新溉路与北滨二路交叉口'},{label:'使用频率',value:'18次/天'},{label:'使用环境',value:'住宅'},{label:'使用单位名称',value:'保利物业管理有限公司'},{label:'使用单位等级',value:'一级'},{label:'电梯使用状态',value:'在用'},
                    ]
                },{
                    title:'维保信息',
                    select:false,
                    icon:require('@/assets/images/title_icon_13.png'),
                    list:[{label:'设备使用地址',value:'新溉路与北滨二路交叉口'},{label:'使用频率',value:'18次/天'},{label:'使用环境',value:'住宅'},{label:'使用单位名称',value:'保利物业管理有限公司'},{label:'使用单位等级',value:'一级'},{label:'电梯使用状态',value:'在用'},
                    ]
                },{
                    title:'保险信息',
                    select:false,
                    icon:require('@/assets/images/title_icon_1.png'),
                    list:[{label:'设备使用地址',value:'新溉路与北滨二路交叉口'},{label:'使用频率',value:'18次/天'},{label:'使用环境',value:'住宅'},{label:'使用单位名称',value:'保利物业管理有限公司'},{label:'使用单位等级',value:'一级'},{label:'电梯使用状态',value:'在用'},
                    ]
                },{
                    title:'检验检测信息',
                    select:false,
                    icon:require('@/assets/images/title_icon_7.png'),
                    list:[{label:'设备使用地址',value:'新溉路与北滨二路交叉口'},{label:'使用频率',value:'18次/天'},{label:'使用环境',value:'住宅'},{label:'使用单位名称',value:'保利物业管理有限公司'},{label:'使用单位等级',value:'一级'},{label:'电梯使用状态',value:'在用'},
                    ]
                },{
                    title:'年检信息',
                    select:false,
                    icon:require('@/assets/images/title_icon_9.png'),
                    list:[{label:'设备使用地址',value:'新溉路与北滨二路交叉口'},{label:'使用频率',value:'18次/天'},{label:'使用环境',value:'住宅'},{label:'使用单位名称',value:'保利物业管理有限公司'},{label:'使用单位等级',value:'一级'},{label:'电梯使用状态',value:'在用'},
                    ]
                },{
                    title:'部件信息',
                    select:false,
                    icon:require('@/assets/images/title_icon_2.png'),
                    list:[{label:'设备使用地址',value:'新溉路与北滨二路交叉口'},{label:'使用频率',value:'18次/天'},{label:'使用环境',value:'住宅'},{label:'使用单位名称',value:'保利物业管理有限公司'},{label:'使用单位等级',value:'一级'},{label:'电梯使用状态',value:'在用'},
                    ]
                },{
                    title:'监管单位信息',
                    select:false,
                    icon:require('@/assets/images/title_icon_6.png'),
                    list:[{label:'设备使用地址',value:'新溉路与北滨二路交叉口'},{label:'使用频率',value:'18次/天'},{label:'使用环境',value:'住宅'},{label:'使用单位名称',value:'保利物业管理有限公司'},{label:'使用单位等级',value:'一级'},{label:'电梯使用状态',value:'在用'},
                    ]
                },{
                    title:'物业单位信息',
                    select:false,
                    icon:require('@/assets/images/title_icon_14.png'),
                    list:[{label:'设备使用地址',value:'新溉路与北滨二路交叉口'},{label:'使用频率',value:'18次/天'},{label:'使用环境',value:'住宅'},{label:'使用单位名称',value:'保利物业管理有限公司'},{label:'使用单位等级',value:'一级'},{label:'电梯使用状态',value:'在用'},
                    ]
                },{
                    title:'供应商信息',
                    select:false,
                    icon:require('@/assets/images/title_icon_5.png'),
                    list:[{label:'设备使用地址',value:'新溉路与北滨二路交叉口'},{label:'使用频率',value:'18次/天'},{label:'使用环境',value:'住宅'},{label:'使用单位名称',value:'保利物业管理有限公司'},{label:'使用单位等级',value:'一级'},{label:'电梯使用状态',value:'在用'},
                    ]
                },{
                    title:'施工方信息',
                    select:false,
                    icon:require('@/assets/images/title_icon_10.png'),
                    list:[{label:'设备使用地址',value:'新溉路与北滨二路交叉口'},{label:'使用频率',value:'18次/天'},{label:'使用环境',value:'住宅'},{label:'使用单位名称',value:'保利物业管理有限公司'},{label:'使用单位等级',value:'一级'},{label:'电梯使用状态',value:'在用'},
                    ]
                },{
                    title:'救援单位信息',
                    select:false,
                    icon:require('@/assets/images/title_icon_8.png'),
                    list:[{label:'设备使用地址',value:'新溉路与北滨二路交叉口'},{label:'使用频率',value:'18次/天'},{label:'使用环境',value:'住宅'},{label:'使用单位名称',value:'保利物业管理有限公司'},{label:'使用单位等级',value:'一级'},{label:'电梯使用状态',value:'在用'},
                    ]
                },{
                    title:'产权单位信息',
                    select:false,
                    icon:require('@/assets/images/title_icon_3.png'),
                    list:[{label:'设备使用地址',value:'新溉路与北滨二路交叉口'},{label:'使用频率',value:'18次/天'},{label:'使用环境',value:'住宅'},{label:'使用单位名称',value:'保利物业管理有限公司'},{label:'使用单位等级',value:'一级'},{label:'电梯使用状态',value:'在用'},
                    ]
                },{
                    title:'制造单位电梯信息',
                    select:false,
                    icon:require('@/assets/images/title_icon_16.png'),
                    list:[{label:'设备使用地址',value:'新溉路与北滨二路交叉口'},{label:'使用频率',value:'18次/天'},{label:'使用环境',value:'住宅'},{label:'使用单位名称',value:'保利物业管理有限公司'},{label:'使用单位等级',value:'一级'},{label:'电梯使用状态',value:'在用'},
                    ]
                },
            ]
        }
    },
    methods:{
        setItem(row,i){
            if(row.select){
                this.$set(this.leftList[i],'select',false)
            }else{
                this.leftList.map((v,z)=>{
                    if(z == i){
                        this.$set(this.leftList[i],'select',true)
                    }else{
                        if(v.select){
                            this.$set(this.leftList[z],'select',false)
                        }
                    }
                })
            }
        }
    }
}
</script>
<style lang="less" scoped>
.laps_list{
    .laps_item{
        .item_title{
            height: .56rem;
            padding: 0 .2rem;
            .txt{
                i{
                    width: .2rem;
                    height: .2rem;
                    margin-right: .1rem;
                }
                span{
                    font-size: .18rem;
                    font-weight: bold;
                    color: #333333;
                }
            }
            .more{
                em{
                    font-size: .16rem;
                    line-height: .16rem;
                    font-weight: 500;
                    color: #666666;
                }
                i{
                    width: .13rem;
                    height: .13rem;
                    margin-left: .11rem;
                }
                label{
                    width: .41rem;
                    height: .27rem;
                }
            }
        }
        .item_box{
            display: none;
            padding: .17rem .07rem .17rem 0rem;
            border-top: 1px solid #DDDDDD;
            height: 2.34rem;
            overflow: hidden;
            transition: all .15s linear;
            .card-info{
                width: 100%;
                height: 100%;
                overflow-y: auto;
                padding-left: .52rem;
                .info-item{
                    height: .35rem;
                    label{
                        font-size: .16rem;
                        font-weight: bold;
                        color: #333333;
                        min-width: 1.52rem;
                    }
                    span{
                        font-size: .16rem;
                        font-weight: 500;
                        color: #666666;
                    }
                }
            }
        }
        &.select{
            .item_box{
                display: block;
            }
        }
    }
}
</style>