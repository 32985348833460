<template>
    <div>
        <Table :tableData="tableData" :tableOptions="tableOption">
            <template v-slot:leval="row">
                <p class="t_red">{{row.data.row.leval}}</p>
            </template>
            <template v-slot:right_scope="data" >
                <el-button type="primary" plain @click="getInfo(data)" size="small">查看</el-button>
            </template>
        </Table>
        <el-drawer
            title="维保详情"
            :visible.sync="drawer"
            append-to-body>
            <div class="mtnInfo">
                <div class="item">
                    <div class="draw_title flex">
                        <i></i>
                        <span>电梯信息</span>
                    </div>
                    <div class="info_are">
                        <div class="info_item flex f_start">
                            <label class="label">电梯</label>
                            <div class="info_main">
                                <span>重庆市/江北区/保利中心/3-A</span><span>886645</span>
                            </div>
                        </div>
                        <div class="info_item flex f_start">
                            <label class="label">告警内容</label>
                            <div class="info_main">
                                <span>电梯门长时间未关闭</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="item">
                    <div class="draw_title flex">
                        <i></i>
                        <span>维保信息</span>
                    </div>
                    <div class="info_are">
                        <div class="info_item flex f_start">
                            <label class="label">维  保 人</label>
                            <div class="info_main f_r">
                                <span>张三</span><span>李四</span><span class="if-box qianmin"><img/></span><span class="if-box qianmin" style="left:1.8rem"><img/></span>
                            </div>
                        </div>
                        <div class="info_item flex f_start">
                            <label class="label">维保时间</label>
                            <div class="info_main">
                                <span>2021-04-19  19:00</span>
                            </div>
                        </div>
                        <div class="info_item flex f_start">
                            <label class="label">维保图片</label>
                            <div class="info_main">
                                <span class="if-box img">
                                    <img/>
                                </span>
                                 <span class="if-box img">
                                    <img/>
                                </span>
                                 <span class="if-box img">
                                    <img/>
                                </span>
                            </div>
                        </div>
                        <div class="info_item flex f_start">
                            <label class="label">备注</label>
                        </div>
                        <div class="info_item flex f_start">
                            <label class="label">维保结果</label>
                            <div class="info_main">
                                <label class="if-box">
                                    <em class="t_gren">正常</em>
                                    <i>3项</i>
                                </label>
                                <label class="if-box">
                                    <em class="t_yel">维修</em>
                                    <i>3项</i>
                                </label>
                                <label class="if-box">
                                    <em class="t_red">更换</em>
                                    <i>3项</i>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="item">
                    <div class="draw_title flex">
                        <i></i>
                        <span>审核信息</span>
                    </div>
                    <div class="info_are">
                        <div class="info_item flex f_start">
                            <label class="label">审  核 人</label>
                            <div class="info_main f_r">
                                <span>张三</span><span>李四</span><span class="if-box qianmin"><img/></span><span class="if-box qianmin" style="left:1.8rem"><img/></span>
                            </div>
                        </div>
                        <div class="info_item flex f_start">
                            <label class="label">审核时间</label>
                            <div class="info_main">
                                <span>2021-04-19  19:00</span>
                            </div>
                        </div>
                        <div class="info_item flex f_start">
                            <label class="label">审核状态</label>
                            <div class="info_main">
                                <span>已审核</span>
                            </div>
                        </div>
                        <div class="info_item flex f_start">
                            <label class="label">审核意见</label>
                            <div class="info_main">
                                <span>审核通过</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="item">
                    <div class="draw_title flex">
                        <i></i>
                        <span>维保信息</span>
                    </div>
                    <div class="info_are">
                        <Table :tableData="tableData1" :tableOptions="tableOption1">
                            <template v-slot:main="row">
                                <h5 class="t_red">{{row.data.row.main.title}}</h5>
                                <p class="t_red">{{row.data.row.main.text}}</p>
                            </template>
                        </Table>
                    </div>
                </div>
            </div>
        </el-drawer>
    </div>
</template>
<script>
import {alarmOption,mtaDrawOptin} from "@/collocat/options.js"
export default {
    name:'mtnrecord',
    data(){
        return{
            tableOption:alarmOption,
            tableOption1:mtaDrawOptin,
            tableData: [
                {
                    leval:"1",   
                    type:1,
                    addr:'加新花园/7-1-2#',
                    navget:'终端',
                    way:1,
                    event:'终端离线',
                    site:'信发和对讲离线',
                    start:'4-14 16:44',
                    end:'4-14 16:44',
                    alarm:'系统'  	              	                     	                      	
                },
                {
                    leval:"4", 
                    type:1,
                    addr:'加新花园/7-1-2#',
                    navget:'终端',
                    way:1,
                    event:'终端离线',
                    site:'信发和对讲离线',
                    start:'4-14 16:44',
                    end:'4-14 16:44',
                    alarm:'系统'  		          	              	                     	                      	
                },
            ],
            tableData1:[
                {
                    main:{
                        title:'机房、轮滑间环境',
                        text:'清洁，门窗完好，照明正常'
                    },
                    remark:'',
                    final:''
                },{
                    main:{
                        title:'机房、轮滑间环境',
                        text:'清洁，门窗完好，照明正常'
                    },
                    remark:'',
                    final:''
                },{
                    main:{
                        title:'机房、轮滑间环境',
                        text:'清洁，门窗完好，照明正常'
                    },
                    remark:'',
                    final:''
                },{
                    main:{
                        title:'机房、轮滑间环境',
                        text:'清洁，门窗完好，照明正常'
                    },
                    remark:'',
                    final:''
                }
            ],
            drawer:false,
        }
    },
    methods:{
        getInfo(row){
            console.log(row);
            this.$set(this,'drawer',true)
        },
    }
}
</script>
<style lang="less" scoped>
.draw_title{
    padding: .0rem .2rem .1rem;
    i{
        width: .04rem;
        height: .14rem;
        background: #2680EB;
        box-shadow: 0px 0px 1px #2680EB;
        border-radius: .2rem;
        margin-right: .08rem;
    }
    span{
        font-size: .18rem;
        font-weight: bold;
        color: #333333;
    }
}
.mtnInfo{
    .item{
        padding: .17rem 0;
        border-bottom: 1px dashed #dddddd;
        .info_are{
            padding: 0 .22rem;
            .info_item{
                padding-left: .14rem;
                font-size: .14rem;
                font-weight: 500;
                line-height: .2rem;
                margin: .03rem 0;
                .label{
                    width: .56rem;
                    text-align-last: justify;
                    color: #999999;
                }
                .info_main{
                    width: calc(100% - .56rem);
                    padding-left: .4rem;
                    color: #333333;
                    span{
                        margin-left: .11rem;
                        &:first-of-type{
                            margin-left: 0;
                        }
                        &.qianmin{
                            width: .71rem;
                            height: .34rem;
                            position: absolute;
                            top: -.07rem;
                        }
                        &.img{
                            width: .71rem;
                            height: .73rem;
                        }
                    }
                    label{
                        margin-left: .3rem;
                        i{
                            margin-left: .05rem;
                        }
                        &:first-of-type{
                            margin-left: 0;
                        }
                    }
                }
            }
        }
        &:last-of-type{
            border-bottom:none
        }
    }
}
</style>