<template>
    <div>
        <el-dialog
            title="急修派单"
            :visible.sync="dialogVisible"
            width="680">
            <el-form :model="form" label-width="128px">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="客户名称">
                            <el-input></el-input>
                        </el-form-item>
                        <el-form-item label="项目名称">
                            <el-input></el-input>
                        </el-form-item>
                        <el-form-item label="电梯安装位置">
                            <el-input></el-input>
                        </el-form-item>
                        <el-form-item label="技术人员">
                            <el-input></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="电梯型号">
                            <el-input></el-input>
                        </el-form-item>
                        <el-form-item label="电梯梯号">
                            <el-input></el-input>
                        </el-form-item>
                        <el-form-item label="保养站">
                            <el-input></el-input>
                        </el-form-item>
                        <el-form-item label="项目/合同编号">
                            <el-input></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-divider></el-divider>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="接报时间">
                            <el-date-picker
                                v-model="form.date"
                                prefix-icon="el-icon-date"
                                type="datetime"
                                placeholder="选择日期时间">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="客户报告急修详情">
                            <el-input
                                type="textarea"
                                :rows="2"
                                placeholder="请输入内容"
                                v-model="form.format">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogVisible = false">派 单</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
export default {
    name:'jixiu',
    data(){
        return{
            dialogVisible: false,
            form:{}
        }
    }
}
</script>