<template>
    <div class="audio_panel f-box f_row">
        <div class="audio_box flex f_r">
            <audio preload="preload" ref="audio" src="" @pause="handPlay(2)" @play="handPlay(1)"
@loadedmetadata="getAudioLength()" @timeupdate="audioTimeUpdate" @click="playAudio">
                您的浏览器不支持 audio 标签
            </audio>
            <div class="play_slider_box">
                <el-slider class="commonSlider" :show-tooltip="false" v-model="initAudio.currentTime" :max="initAudio.audioLength" @change="changeAudioTime">
                </el-slider>
            </div>
            <a class="if-box play_btn" @click="playAudio">
                <img/>
            </a>
        </div>
        <p>时长：3分15秒</p>
    </div>
</template>
<script>
export default {
    name:'audiomode',
    data(){
        return{
            initAudio: {
                play: false,//播放还是暂停 true播放中
                audioLength: 0,//audio时长
                url: "",//音频课件url
                currentTime: 0,//当前播放时间
                lastTime: null,//标记时间戳
                name: "",
            },
        }
    },
    methods:{
        playAudio() {//播放音频
            if (this.initAudio.play) {
                this.$refs.audio.pause();
            } else {
                Math.abs(this.initAudio.currentTime - this.$refs.audio.currentTime) > 2 ? this.$refs.audio.currentTime = this.initAudio.currentTime : '';
                this.$refs.audio.play();
                this.$refs.audio.muted = false
            }
        },
        audioTimeUpdate() {//更新音频时间。节流，每秒触发一次
            let nowTime = Date.now()
            let gapTime = 1000;
            if (!this.initAudio.lastTime || nowTime - this.initAudio.lastTime > gapTime) {
                if (this.$refs.audio) {
                    let time = this.$refs.audio.currentTime;
                    this.initAudio.currentTime = time;
                }
                this.initAudio.lastTime = nowTime
            }
        },
        changeAudioTime(val) {//改变视频时间
            if (this.$refs.audio) {
                this.$refs.audio.currentTime = val;
            }
        },
        getAudioLength() {//获取音视频时长 1音频 2视频
            this.initAudio.audioLength = this.$refs.audio.duration
        },
        handPlay(status) {//音视频播放暂停 type:1 音频 2视频 status 1播放 2暂停
            status == 1 ? this.initAudio.play = true : this.initAudio.play = false;
        },
    }
}
</script>
<style lang="less" scoped>
.audio_panel{
    width: 100%;
    height: .8rem;
    padding: 0 .2rem;
    background-color: rgba(2,120,241,.07);
    border-radius: 6px;
    .audio_box{
        width: 100%;
        position: relative;
        audio{
            width: 100%;
            opacity: 0;
        }
        .play_slider_box{
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: .3rem;
            margin: auto;
            /deep/.el-slider{
                .el-slider__runway{
                    height: .04rem;
                    margin: auto;
                    .el-slider__bar{
                        height: .04rem;
                    }
                    .el-slider__button-wrapper{
                        height: .36rem;
                        width: .36rem;
                        top: -.17rem;
                        .el-slider__button{
                            width: .12rem;
                            height: .12rem;
                        }
                    }
                }
            }
        }
        a{
            width: .3rem;
            height: .3rem;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            margin: auto;
        }
    }
    p{
        width: 100%;
        font-size: .12rem;
        font-weight: 500;
        color: #999999;
        margin-top: .15rem;
    }
}
</style>