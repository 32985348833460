
/* eslint-disable */
// import {Base64} from '@/utils/base64.min.js'
let idTmr;
const getExplorer = () => {
  let explorer = window.navigator.userAgent;
  //ie
  if (explorer.indexOf("MSIE") >= 0) {
    return 'ie';
  }
  //firefox
 
  else if (explorer.indexOf("Firefox") >= 0) {
    return 'Firefox';
  }
  //Chrome
  else if (explorer.indexOf("Chrome") >= 0) {
    return 'Chrome';
  }
  //Opera
  else if (explorer.indexOf("Opera") >= 0) {
    return 'Opera';
  }
  //Safari
  else if (explorer.indexOf("Safari") >= 0) {
    return 'Safari';
  }
}
// 判断浏览器是否为IE
const exportToExcel = (data,name) => {
 
  // 判断是否为IE
  if (getExplorer() == 'ie') {
    tableToIE(data, name)
  } else {
    tableToNotIE(data,name)
  }
}
 
const Cleanup = () => {
  window.clearInterval(idTmr);
}
 
// ie浏览器下执行
const tableToIE = (data, name) => {
  let curTbl = data;
  let oXL = new ActiveXObject("Excel.Application");
 
  //创建AX对象excel
  let oWB = oXL.Workbooks.Add();
  //获取workbook对象
  let xlsheet = oWB.Worksheets(1);
  //激活当前sheet
  let sel = document.body.createTextRange();
  sel.moveToElementText(curTbl);
  //把表格中的内容移到TextRange中
  sel.select;
  //全选TextRange中内容
  sel.execCommand("Copy");
  //复制TextRange中内容
  xlsheet.Paste();
  //粘贴到活动的EXCEL中
 
  oXL.Visible = true;
  //设置excel可见属性
 
  try {
    let fname = oXL.Application.GetSaveAsFilename("Excel.xls", "Excel Spreadsheets (*.xls), *.xls");
  } catch (e) {
    print("Nested catch caught " + e);
  } finally {
    oWB.SaveAs(fname);
 
    oWB.Close(savechanges = false);
    //xls.visible = false;
    oXL.Quit();
    oXL = null;
    // 结束excel进程，退出完成
    window.setInterval("Cleanup();", 1);
    idTmr = window.setInterval("Cleanup();", 1);
  }
}
 
// 非ie浏览器下执行
const tableToNotIE = (function() {
  // 编码要用utf-8不然默认gbk会出现中文乱码
  var worksheet = {
    t: "s",
  }
  let uri = 'data:application/vnd.ms-excel;base64,',
    template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta charset="UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><style>.text{mso-number-format:"\@"}</style></head><body><table>{table}</table></body></html>',
    base64 = function(s) {
      return window.btoa(unescape(encodeURIComponent(s)));
    },
    format = (s, c) => {
      return s.replace(/{(\w+)}/g,
        (m, p) => {
          return c[p];
        })
    }
  return (table, name) => {
    let ctx = {
      worksheet: name,
      table
    }
    var fom = format(template, ctx)
    //创建下载
    let link = document.createElement('a');
    var url = uri + base64(fom)
    link.setAttribute('href', url);
    link.setAttribute('download', name);
    // window.location.href = uri + base64(format(template, ctx))
    link.click();
  }
})()

// 导出函数
const export2Excel = (theadData, filterVal,tbodyData, dataname) => {
    let re = /http/ // 字符串中包含http,则默认为图片地址
    let th_len = theadData.length // 表头的长度
    let tb_len = tbodyData.length // 记录条数
    let width = 80 // 设置图片大小
    let height = 80
    // getBase64Image =function(img) { 
    //   var canvas = document.createElement("canvas");  
    //   canvas.width = img.width;  
    //   canvas.height = img.height;  
    //   var ctx = canvas.getContext("2d");  
    //   ctx.drawImage(img, 0, 0, img.width, img.height);  
    //   var ext = img.src.substring(img.src.lastIndexOf(".")+1).toLowerCase();  
    //   var dataURL = canvas.toDataURL("image/"+ext);  
    //   console.log(dataURL);
    //   return dataURL;   
      
    // }  
   
    // 添加表头信息
    let thead = '<thead><tr>'
    for (let i = 0; i < th_len; i++) {
      thead += '<th>' + theadData[i] + '</th>'
    }
    thead += '</tr></thead>'
   
    // 添加每一行数据
    let tbody = '<tbody>'
    for (let i = 0; i < tb_len; i++) {
      tbody += '<tr>'
      var data = {};
      for (var z = 0; z < filterVal.length; z++) {
          var k = filterVal[z];
          if(!data[k]){
            data[k] = tbodyData[i][k]?tbodyData[i][k]:''
          }
      }
      let row = data // 获取每一行数据
      for (let key in row) {
        // (typeof row[key] == 'object') && row[key].type&&row[key].type == 'image'
          if(re.test(row[key])&&(row[key].rowType&&row[key].rowType=='imgs')){
            if(row[key].urls){
                var str = '',num=0
                for (let i = 0; i < row[key].urls.length; i++) {
                  // var image = new Image();  
                  // image.crossOrigin = '';
                  // image.src = row[key].urls[i];  
                  // image.onload = function(){  
                  //   var base64Url = getBase64Image(image)
                  //   console.log(base64Url);
                    
                  // }
                  str+='<img src=\"' + row[key].urls[i] + '\" ' + ' ' + 'width=' + '\"' + width + '\"' + ' ' + 'height=' + '\"' + height + '\"' + ' style="display:inline-block">\&nbsp;'
                  num+=1;
                }
                tbody += '<td style="width:' + (width*num+20) + 'px; height:' + height + 'px; text-align: center; vertical-align: middle;padding-left:10px"><div style="display:flex;width: 100%;">'+str+'</div></td>'
            }else{
              // var image = new Image();  
              // image.src = row[key].url;  
              // image.crossOrigin = '';
              // image.onload = function(){  
              //   var base64Url = getBase64Image(image)
                
              // }
              tbody += '<td style="width:' + (width+20) + 'px; height:' + height + 'px; text-align: center; vertical-align: middle;padding-left:10px"><div style="display:inline-flex;width: 100%;"><img src=\"' + row[key].url + '\" ' + ' ' + 'width=' + '\"' + width + '\"' + ' ' + 'height=' + '\"' + height + '\"' + '></div></td>'
            }
            // tbody += '<td style="width:' + width + 'px; height:' + height + 'px; text-align: center; vertical-align: middle"><div style="display:inline"><img src=\'' + row[key] + '\' ' + ' ' + 'width=' + '\"' + width + '\"' + ' ' + 'height=' + '\"' + height + '\"' + '></div></td>'
        }else{
          tbody += '<td class="text" style="text-align:center">' + row[key]+'' + '</td>'
        }
      }
      tbody += '</tr>'
    }
    tbody += '</tbody>'
    let table =thead + tbody
    // 导出表格
    exportToExcel(table, dataname)
}
export{export2Excel} 