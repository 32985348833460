<template>
    <div>
        <el-row :gutter="10">
            <el-col :span="14">
                <div>
                    <div class="list_title panel_1 flex f_r">
                        <label class="if-box">
                            <img src="@/assets/images/working_icon.png"/>
                        </label>
                        <span>运行数据</span>
                    </div>
                    <div class="panel_1 data_info">
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <div class="line-box f-box f_row lift_img-box">
                                    <label class="if-box floor_icon" :style="'backgroundImage: url('+require('@/assets/images/panel_icon.png')+')'">
                                        <em>18</em>
                                        <i class="if-box">
                                            <img/>
                                        </i>
                                    </label>
                                    <div class="lift_are">
                                        <img/>
                                    </div>
                                    <div class="lift_sys f-box">
                                        <span class="if-box btn b_blu">
                                            <i class="if-box">
                                                <img/>
                                            </i>
                                            查看监控
                                        </span>
                                        <span class="if-box btn b_blu">
                                            <i class="if-box">
                                                <img/>
                                            </i>
                                            语音呼叫
                                        </span>
                                    </div>
                                </div>
                                <div class="line-box mt_40 f_r norm_box">
                                    <div class="normList flex f_warp">
                                        <div class="norm_item flex">
                                            <div class="if-box label">
                                                <label class="if-box">
                                                    <img/>
                                                </label>
                                                <span>供电：</span>
                                            </div>
                                            <span>正常</span>
                                        </div>
                                        <div class="norm_item flex">
                                            <div class="if-box label">
                                                <label class="if-box">
                                                    <img/>
                                                </label>
                                                <span>供电：</span>
                                            </div>
                                            <span>正常</span>
                                        </div>
                                        <div class="norm_item flex">
                                            <div class="if-box label">
                                                <label class="if-box">
                                                    <img/>
                                                </label>
                                                <span>供电：</span>
                                            </div>
                                            <span>正常</span>
                                        </div>
                                    </div>
                                    <label class="title_btn f-box" :style="'backgroundImage: url('+require('@/assets/images/greenbtn_bg.png')+')'">故障状态监测</label>
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <div class="line-box f_r norm_box">
                                    <div class="normList flex f_warp">
                                        <div class="norm_item flex">
                                            <div class="if-box label">
                                                <span>供电：</span>
                                            </div>
                                            <span>正常</span>
                                        </div>
                                    </div>
                                    <label class="title_btn f-box" :style="'backgroundImage: url('+require('@/assets/images/greenbtn_bg.png')+')'">实时数据</label>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </el-col>
            <el-col :span="10">
                <div>
                    <div class="list_title panel_1 flex f_r">
                        <label class="if-box">
                            <img src="@/assets/images/working_icon.png"/>
                        </label>
                        <span>故障数据</span>
                        <em class="if-box">查看更多</em>
                    </div>
                    <div class="war_list">
                        <div class="war_item panel_1">
                            <div class="title flex f_between">
                                <span>2021-04-05 22:36:15</span>
                                <label>视频报警</label>
                            </div>
                            <p>视频报警（无关）</p>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
export default {
    name:'syndata'
}
</script>
<style lang="less" scoped>
.list_title{
    padding: 0 .18rem;
    height: .56rem;
    label{
        width: .21rem;
        height: .21rem;
        margin-right: .11rem;
    }
    span{
        font-size: .16rem;
        font-weight: bold;
        color: #333333;
    }
    em{
        position: absolute;
        top: 0;
        bottom: 0;
        right: .18rem;
        margin: auto;
        font-size: .14rem;
        font-weight: 500;
        color: #333333;
    }
}
.data_info{
    padding: .31rem .25rem .23rem;
    .line-box{
        min-height: 3.4rem;
        background: #FBFCFE;
        border: 1px solid #E2EEFB;
        opacity: 1;
        border-radius: .06rem;
    }
}
.mt_40{
    margin-top: .4rem;
}
.lift_img-box{
    .floor_icon{
        width: .95rem;
        height: .42rem;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        font-size: .14rem;
        font-weight: bold;
        color: #FAFCFF;
        i{
            width: .12rem;
            height: .12rem;
            margin-left: .08rem;
        }
    }
    .lift_are{
        width: 1.99rem;
        height: 1.75rem;
        margin: .15rem 0 .27rem;
    }
   .lift_sys{
       .btn{
            height: .32rem;
            box-shadow: 0px .03rem .06rem rgba(25, 86, 211, 0.25);
            border-radius: .06rem;
            padding: 0 .18rem 0 .15rem;
            font-size: .16rem;
            font-weight: 400;
            cursor: pointer;
            color: #FFFFFF;
            margin: 0 .05rem;
            i{
                width: .17rem;
                height: .17rem;
                margin-right: .06rem;
            }
        }
   } 
}
.norm_box{
    padding-top: .39rem;
}
.normList{
    .norm_item{
        width: 50%;
        font-size: .16rem;
        height: .35rem;
        .label{
            width: 50%;
            label{
                width: .12rem;
                height: .18rem;
                margin-right: .12rem;
            }
            span{
                font-weight: bold;
                color: #333333;
            }
        }
        span{
            font-weight: 500;
            color: #999999;
        }
    }
}
.title_btn{
    width: 2.06rem;
    height: .34rem;
    font-size: .18rem;
    font-weight: bold;
    color: #FFFFFF;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: -.17rem;
    left: 0;
    right: 0;
    margin: auto;
}
.war_list{
    .war_item{
        padding: .13rem .21rem;
        .title{
            margin-bottom: .13rem;
            span{
                font-size: .14rem;
                font-weight: 500;
                color: #999999;
            }
            label{
                font-size: .14rem;
                font-weight: 500;
                color: #333333;
            }
        }
        p{
            font-size: .16rem;
            font-weight: 500;
            color: #333333;
        }
    }
}
</style>