<template>
    <div v-loading="loading">
        <el-row :gutter="10">
            <el-col :span="8">
                <div class="panel line_one">
                    <div class="panel-box">
                        <div class="p_title flex f_r">
                            <label class="icon if-box">
                                <img src="@/assets/images/regist_info.png"/>
                            </label>
                            <p>使用登记证信息</p>
                            <a class="code if-box" id="lift_code">
                                <el-image 
                                    :src="liftMain.imgUrl" 
                                    :preview-src-list="[liftMain.imgUrl]"
                                    lazy>
                                </el-image>
                            </a>
                        </div>
                        <div class="p_main">
                            <ul class="info_box">
                                <li class="flex f_start info_item">
                                    <label>注册代码</label>
                                    <p>{{liftMain.sn}}</p>
                                </li>
                                <li class="flex f_start info_item">
                                    <label>
                                        设备种类</label>
                                    <p>{{liftMain.devType}}</p>
                                </li>
                                <li class="flex f_start info_item">
                                    <label>
                                        电梯编码</label>
                                    <p>{{liftMain.code}}</p>
                                </li>
                                <li class="flex f_start info_item">
                                    <label>
                                        登记证编码</label>
                                    <p>{{liftMain.regNo}}</p>
                                </li>
                                <li class="flex f_start info_item">
                                    <label>
                                        使用单位</label>
                                    <p>{{liftMain.ownerCorpName}}</p>
                                </li>
                                <li class="flex f_start info_item">
                                    <label>
                                        单位内编号</label>
                                    <p>{{liftMain.liftName}}</p>
                                </li>
                                <li class="flex f_start info_item">
                                    <label>
                                        登记机关</label>
                                    <p>{{liftMain.regCorpName}}</p>
                                </li>
                                <li class="flex f_start info_item">
                                    <label>
                                        检验机构</label>
                                    <p>{{liftMain.rcCcCorpName}}</p>
                                </li>
                                <li class="flex f_start info_item">
                                    <label>
                                        下次检验日期</label>
                                    <p>{{liftMain.rcCcNext}}</p>
                                </li>
                                <li class="flex f_start info_item">
                                    <label>
                                        维保单位</label>
                                    <p>{{liftMain.mcCorpName}}</p>
                                </li>
                                <li class="flex f_start info_item">
                                    <label>
                                        应急救援电话</label>
                                    <p>{{liftMain.rescuePhone}}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </el-col>
            <el-col :span="10">
                <div class="panel line_one">
                    <baidu-map 
                        class="map_are" 
                        ak="smGKihmYZaEBIPyib3H4lzkcpoSnKAWI" 
                        :center="map.center" 
                        :zoom="map.zoom"
                        :scroll-wheel-zoom='true'
                        @ready="mapInit"
                        :auto-viewport="true"
                        >
                        <bm-driving
                        :start="map.center"
                        :end="map.end"
                        :auto-viewport="true"
                        policy="BMAP_DRIVING_POLICY_LEAST_DISTANCE"
                        :selectFirstResult="true"
                        @searchcomplete="complete"
                        :panel="false"></bm-driving>
                        <bm-marker :position="map.center" :icon="{url: lac_over, size: {width: 20, height: 20}}">
                            
                        </bm-marker>
                        
                        <bm-marker :position="map.end" :icon="{url: lac_start, size: {width: 20, height: 20}}">
                            <bm-overlay
                                :show="map.show"
                                pane="labelPane"
                                class="sample"
                                ref="customOverlay"
                                @draw="draw">
                                <div class="infoBox">
                                    <div class="flex">
                                        <label class="if-box">
                                            <img src="@/assets/images/mtc-er.png"/>
                                        </label>
                                        <span>{{map.infoMain.name}}({{map.infoMain.tel}})</span>
                                    </div>
                                    <div class="txt">{{map.infoMain.str}}</div>
                                </div>
                            </bm-overlay>
                        </bm-marker>
                    </baidu-map>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="panel line_one">
                     <div class="panel-box">
                        <div class="p_title flex f_r">
                            <label class="icon if-box">
                                <img src="@/assets/images/working_icon.png"/>
                            </label>
                            <p>电梯运行状态</p>
                        </div>
                        <p class="if-box empty_are">暂无数据</p>
                        <!-- <div class="p_main">
                            <ul class="info_box f_r small">
                                <li class="flex f_start info_item">
                                    <label>电梯故障</label>
                                    <p>123</p>
                                </li>
                                <li class="flex f_start info_item">
                                    <label>终端故障</label>
                                    <p>123</p>
                                </li>
                                <li class="flex f_start info_item">
                                    <label>维保模式</label>
                                    <p>123</p>
                                </li>
                                <li class="flex f_start info_item">
                                    <label>重点监控</label>
                                    <p>123</p>
                                </li>
                                <li class="flex f_start info_item">
                                    <label>实时监控</label>
                                    <p>123</p>
                                </li>
                                <li class="flex f_start info_item">
                                    <label>是否有人</label>
                                    <p>123</p>
                                </li>
                                <li class="flex f_start info_item">
                                    <label>通讯时间</label>
                                    <p>123</p>
                                </li>
                                <li class="flex f_start info_item">
                                    <label>轿厢温度</label>
                                    <p>123</p>
                                </li>
                                <li class="flex f_start info_item">
                                    <label>层站数</label>
                                    <p>123</p>
                                </li>
                                <div class="lift_img">
                                    <div class="f-box f_row">
                                        <label class="leavl f-box" :style="'backgroundImage: url('+require('@/assets/images/panel_icon.png')+')'">
                                            18
                                            <i>
                                                <img src=""/>
                                            </i>
                                        </label>
                                        <label class="lift_box f-box">
                                            <img src=""/>
                                        </label>
                                    </div>
                                </div>
                            </ul>
                            <div class="lift_sys_tab f-box">
                                <label class="if-box b_blue sys_btn">
                                    <i class="if-box">
                                        <img/>
                                    </i>
                                    查看监控
                                </label>
                                <label class="if-box b_blue sys_btn">
                                    <i class="if-box">
                                        <img/>
                                    </i>
                                    语音呼叫
                                </label>
                            </div>
                        </div> -->
                     </div>
                </div>
            </el-col>
        </el-row>
        <el-row :gutter="10">
            <el-col :span="8">
                <div class="panel line_two">
                    <div class="panel-box">
                        <div class="p_title flex f_r">
                            <label class="icon if-box">
                                <img src="@/assets/images/mg-unit.png"/>
                            </label>
                            <p>管理单位</p>
                        </div>
                        <div class="p_main">
                            <div class="guanli">
                                <div class="item flex f_between f_start">
                                    <div class="left">
                                        <h5>维保公司</h5>
                                        <label class="if-box icon">
                                            <img src="@/assets/images/mtc-cp.png"/>
                                        </label>
                                    </div>
                                    <div class="right">
                                        <h5>{{liftMain.mcCorpName}}</h5>
                                        <p class="flex f_between" v-for="(item,index) in liftMain.rcMcUidsList" :key="index">
                                            <span>维保人员{{index+1+'：'+item.name+'('+item.tel+')'}}</span> 
                                            <label class="if-box" @click="setLine(item)"><img src="@/assets/images/data_usrmap.png"/></label>
                                        </p>
                                        <p class="flex f_between" v-for="(item,index) in liftMain.rcMcAdminUidsList" :key="item.id">
                                            <span>维保经理{{index+1+'：'+item.name+'('+item.tel+')'}}</span> 
                                            <label class="if-box" @click="setLine(item)"><img src="@/assets/images/data_usrmap.png"/></label>
                                        </p>
                                    </div>
                                </div>
                                <div class="item flex f_between f_start">
                                    <div class="left">
                                        <h5>物业公司</h5>
                                        <label class="if-box icon">
                                            <img src="@/assets/images/pp-cp.png"/>
                                        </label>
                                    </div>
                                    <div class="right">
                                        <h5>{{liftMain.pmCorpName}}</h5>
                                        <p class="flex f_between" v-for="(item,index) in liftMain.rcPmUidsList" :key="index">
                                            <span>安管员{{index+1+'：'+item.name+'('+item.tel+')'}}</span> 
                                            <label class="if-box" @click="setLine(item)"><img src="@/assets/images/data_usrmap.png"/></label>
                                        </p>
                                        <p class="flex f_between" v-for="(item,index) in liftMain.rcPmAdminUidsList" :key="item.id">
                                            <span>物业经理{{index+1+'：'+item.name+'('+item.tel+')'}}</span> 
                                            <label class="if-box" @click="setLine(item)"><img src="@/assets/images/data_usrmap.png"/></label>
                                        </p>
                                    </div>
                                </div>
                                <div class="item flex f_between f_start">
                                    <div class="left">
                                        <h5>监管单位</h5>
                                        <label class="if-box icon">
                                            <img src="@/assets/images/sp-unit.png"/>
                                        </label>
                                    </div>
                                    <div class="right">
                                        <h5>{{liftMain.regCorpName}}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>
            <el-col :span="10">
                <div class="line_two">
                    <el-row :gutter="10">
                        <el-col :span="12">
                            <div class="panel carve_item panel-box">
                                <div class="p_title flex f_r">
                                    <i></i>
                                    <p>重大情况</p>
                                </div>
                                <div class="carve_box flex f_row f_arr">
                                    <div class="flex progr_item" v-for="(item,index) in liftMain.majorSituation" :key="index">
                                        <label>{{item.type == 1?'已处理':'待处理'}}</label>
                                        <div class="f-box progr_box">
                                            <el-progress :percentage="item.count" :format="format" :class="{'b_gree':item.type == 1}"></el-progress>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="panel carve_item panel-box">
                                <div class="p_title flex f_r">
                                    <i></i>
                                    <p>困人</p>
                                </div>
                                <div class="carve_box flex f_row f_arr">
                                    <div class="flex progr_item" v-for="(item,index) in liftMain.tiring" :key="index">
                                        <label>{{item.type == 1?'已处理':'待处理'}}</label>
                                        <div class="f-box progr_box">
                                            <el-progress :percentage="item.count" :format="format" :class="{'b_gree':item.type == 1}"></el-progress>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="panel carve_item panel-box">
                                <div class="p_title flex f_r">
                                    <i></i>
                                    <p>投诉咨询</p>
                                </div>
                                <div class="carve_box flex f_row f_arr">
                                    <div class="flex progr_item" v-for="(item,index) in liftMain.complaint" :key="index">
                                        <label>{{item.type == 1?'已处理':'待处理'}}</label>
                                        <div class="f-box progr_box">
                                            <el-progress :percentage="item.count" :format="format" :class="{'b_gree':item.type == 1}"></el-progress>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <div class="panel carve_item panel-box">
                                <div class="p_title flex f_r">
                                    <i></i>
                                    <p>维保</p>
                                </div>
                                <div class="carve_box_right flex">
                                    <label class="if-box">
                                        <img src="@/assets/images/mtc-cp.png"/>
                                    </label>
                                    <div class="carve_main">
                                        <p>下次维保日期</p>
                                        <h5>{{liftMain.dtm?liftMain.dtm:'暂无信息'}}</h5>
                                    </div>
                                </div>
                            </div>
                            <div class="panel carve_item panel-box">
                                <div class="p_title flex f_r">
                                    <i></i>
                                    <p>检验检测</p>
                                </div>
                                <div class="carve_box_right flex">
                                    <label class="if-box">
                                        <img src="@/assets/images/testing.png"/>
                                    </label>
                                    <div class="carve_main">
                                        <p>下次年检日期</p>
                                        <h5>{{liftMain.rcCcNext?liftMain.rcCcNext:'暂无信息'}}</h5>
                                    </div>
                                </div>
                            </div>
                            <div class="panel carve_item panel-box">
                                <div class="p_title flex f_r">
                                    <i></i>
                                    <p>电梯保险</p>
                                    <em class="if-box b_yel" v-if="liftMain.insureType==0">未购买</em>
                                    <em class="if-box b_gre" v-if="liftMain.insureType==1">已购买</em>
                                    <em class="if-box b_red" v-if="liftMain.insureType==2">已过期</em>
                                </div>
                                <div class="carve_box_right flex">
                                    <label class="if-box">
                                        <img src="@/assets/images/lift_baoxian.png"/>
                                    </label>
                                    <div class="carve_main">
                                        <p>保险到期日期</p>
                                        <h5>{{liftMain.insureExp?liftMain.insureExp:'暂无信息'}}</h5>
                                    </div>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="panel line_two">
                    <div class="panel-box">
                        <div class="p_title flex f_r">
                            <label class="icon if-box">
                                <img src="@/assets/images/warning_icon.png"/>
                            </label>
                            <p>告警</p>
                        </div>
                        <p class="if-box empty_are">暂无数据</p>
                        <!-- <div class="war_list">
                            <div class="war_item">
                                <div class="flex f_between title">
                                    <label>运行告警</label>
                                    <em class="t_blue">四级</em>
                                </div>
                                <div class="main_are">
                                    <p class="flex">
                                        <label class="if-box">
                                            <img src="@/assets/images/warning_title.png"/>
                                        </label>
                                        终端离线
                                    </p>
                                    <p class="flex">
                                        <label class="if-box">
                                            <img src="@/assets/images/baojing_time.png"/>
                                        </label>
                                        报警时间：05-16 08:26:32
                                    </p>
                                </div>
                            </div>
                            <div class="war_item">
                                <div class="flex f_between title">
                                    <label>运行告警</label>
                                    <em class="t_blue">四级</em>
                                </div>
                                <div class="main_are">
                                    <p class="flex">
                                        <label class="if-box">
                                            <img src="@/assets/images/warning_title.png"/>
                                        </label>
                                        终端离线
                                    </p>
                                    <p class="flex">
                                        <label class="if-box">
                                            <img src="@/assets/images/baojing_time.png"/>
                                        </label>
                                        报警时间：05-16 08:26:32
                                    </p>
                                </div>
                            </div>
                            <div class="war_item">
                                <div class="flex f_between title">
                                    <label>运行告警</label>
                                    <em class="t_blue">四级</em>
                                </div>
                                <div class="main_are">
                                    <p class="flex">
                                        <label class="if-box">
                                            <img src="@/assets/images/warning_title.png"/>
                                        </label>
                                        终端离线
                                    </p>
                                    <p class="flex">
                                        <label class="if-box">
                                            <img src="@/assets/images/baojing_time.png"/>
                                        </label>
                                        报警时间：05-16 08:26:32
                                    </p>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import codeLift from "@/assets/images/discern_code.png"
import start_lac from "@/assets/images/start_lca.png"
import over_lac from "@/assets/images/over_lca.png"
import mter from "@/assets/images/mtc-er.png"
// import BMapLib from "@/assets/InfoBox_min.js"
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import {BmMarker,BmDriving, BmInfoWindow, BmNavigation,BmView,BmOverlay} from 'vue-baidu-map'
var map;
export default {
    name:'data-view',
    components: {
        BaiduMap,
        // BmView
        BmOverlay,
        BmDriving,
        // BmNavigation,
        BmMarker,
        // BmInfoWindow
    },
    props: {
        liftMain:{
            type: Object,
            default: ()=>{}
        },
    },
    data(){
        return{
            map:{
                center: {lng: 0, lat: 0},
                zoom: 15,
                show: false,
                end:{lng:0,lat:0,},
                driShow:false,
                infoMain:''
            },//地图对象
            BMap:'',
            tmap:'',
            zoom:15,
            lac_start:start_lac,
            lac_over:over_lac,
            srcList:[
                codeLift
            ],
            reportUrl:'../../components/test.html',
            loading:false
        }
    },
    created(){
    },
    mounted() {
        // this.mapInit();
	},
    methods:{
        format(percentage) {
            return percentage === 100 ? '100' : `${percentage}`;
        },
        mapInit({ BMap, map }){
            this.BMap = BMap;
            this.tmap = map;
            
            // map = new BMapGL.Map("allmap");
            var c = this.liftMain.liftPoint.split(',')
            let temp='';
            // if(center==="null"){
                temp = {
                    lng:c[0],
                    lat:c[1]
                }
                // alert("位置获取失败");
                var point = new BMap.Point(temp.lng,temp.lat);//地图中心点
                // map.centerAndZoom(point, 15);
            // }else{
            //     let p = center.split(',');
            //     temp = {
            //         lng:p[0],
            //         lat:p[1]
            //     }
            //     // var point = new BMapGL.Point(p[0],p[1]);//地图中心点
            //     // map.centerAndZoom(point, 15);
            // }
            this.$set(this.map,'center',temp)
            this.$refs.customOverlay.reload()
            // map.enableScrollWheelZoom(true);     //开启鼠标滚轮缩放
            /* var scaleCtrl = new BMapGL.ScaleControl();  // 添加比例尺控件
            map.addControl(scaleCtrl); */
            // var zoomCtrl = new BMapGL.ZoomControl();  // 添加比例尺控件
            // map.addControl(zoomCtrl);
            // var myIcon1 = new BMap.Icon(this.lac_start, new BMap.Size(20, 20));
            // var myStart = new BMap.Marker(point,{icon:myIcon1}); 
            // BMap.OverviewMapControl(myStart);
            // console.log(window)
        },
        draw ({el, BMap, map}) {
            var arr = [this.map.end.lng,this.map.end.lat]
            const pixel = map.pointToOverlayPixel(new BMap.Point(arr[0],arr[1]))
            el.style.left = pixel.x + 0 + 'px'
            el.style.top = pixel.y - 110 + 'px'
        },
        complete(res){
            console.log(res);
            if(res){
                this.$set(this,'loading',false)
            }
            if(this.map.driShow){
                var output = {}
                var plan = res.getPlan(0);
                output.time =  plan.getDuration(true); //获取时间
                output.line = plan.getDistance(true); //获取距离
                var str = "距离"+output.line+"公里 约"+output.time
                this.$set(this.map.infoMain,'str',str)
            }
        },
        setLine(item){
            console.log(item);
            // let BMap = this.BMap;
            // let map = this.tmap;
            // var c = item.point.split(',');
            // var temp = {
            //     lng:c[0],
            //     lat:c[1]
            // }
            // var output = {}
            // var arr = [this.map.center.lng,this.map.center.lat]
            // var start=new BMap.Point(temp.lng,temp.lat);//导航起点
            // var end=new BMap.Point(arr[0],arr[1]);//导航终点
            // var transit = new BMap.DrivingRoute(map, {renderOptions: {map: map},
            //     onSearchComplete: function (results){
            //         console.log(results);
            //         if (transit.getStatus() != BMAP_STATUS_SUCCESS){
            //             return ;
            //         }
            //         var plan = results.getPlan(0);
            //         output.time =  plan.getDuration(true); //获取时间
            //         output.line = plan.getDistance(true); //获取距离
            //         console.log(output);
            //     },
            // });
            // transit.search(start, end);
            if(item.point&&item.point!=''){
                var c = item.point.split(',');
                var temp = {
                    lng:c[0],
                    lat:c[1]
                }
                var info = {
                    // infoMain
                    name:item.name,
                    tel:item.tel
                }
                this.$set(this.map,'end',temp)
                this.$set(this.map,'infoMain',info)
                this.$set(this,'loading',true)
                this.$set(this.map,'driShow',true)
                // var _this = this;
                // setTimeout(function(){
                //     _this.$set(_this.map,'driShow',true)
                // },1000)
            }
            // console.log(item);
            // // end:{lng:0,lat:0,},
            // return false
            //点击维保人员位置，绘制导航路径
            
        }
    }
}
</script>
<style lang="less" scoped>
.line_one{height: 449px;}
.line_two{max-height: 428px}
.panel{margin-bottom: .1rem;}
.panel-box{
    width: 100%;
    height: 100%;
    position: relative;
    padding: .13rem .12rem;
    white-space: nowrap;
    .p_title{
        height: .38rem;
        .icon{
            width: .3rem;
            height: .3rem;
            margin-right: .06rem;
        }
        p{
            font-size: .16rem;
            font-weight: bold;
            color: #333333;
        }
        a.code{
            width: .57rem;
            height: .38rem;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            margin: auto;
        }
        i{
            width: .03rem;
            height: .14rem;
            background: #FFFFFF;
            border: 2px solid #0278F1;
            margin-right: .13rem;
            margin-left: .06rem;
        }
        em{
            font-size: .14rem;
            font-weight: 400;
            min-width:1rem;
            height:.25rem;
            border-radius: 0 .1rem 0 .5rem;
            position: absolute;
            top: -.14rem;
            right: -.12rem;
            font-size: .14rem;
            color: #ffffff;
        }
    }
    .p_main{
        padding-left: .37rem;
        .info_box{
            .info_item{
                height: .35rem;
                line-height: .32rem;
                label{
                    width: .96rem;
                    font-size: .14rem;
                    font-weight: bold;
                    color: #333333;
                }
                p{
                    padding-left: .18rem;
                    font-size: .16rem;
                    font-weight: 400;
                    color: #666666;
                }
            }
            .lift_img{
                width: 1.5rem;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                background-color: rgba(2,120,241,.02);
                .leavl{
                    width: .64rem;
                    height: .26rem;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    font-size: .14rem;
                    font-weight: bold;
                    color: #FAFCFF;
                    margin: .27rem 0 .14rem;
                    i{
                        width: .12rem;
                        height: .12rem;
                        margin-left: .08rem;
                    }
                }
                .lift_box{
                    width: 1.13rem;
                    height: 1.01rem;
                }
            }
            &.small{
                .info_item{
                    label{
                        width:.64rem
                    }
                    p{
                        padding-left: .28rem;
                    }
                }
            }
        }
        .guanli{
            padding: 0 .08rem 0 .15rem;
            .item{
                padding: .17rem 0;
                border-bottom: 1px solid #DDDDDD;
                .left{
                    width: .96rem;
                    h5{
                        font-size: .14rem;
                        font-weight: bold;
                        color: #333333;
                        margin-bottom: .17rem;
                    }
                    .icon{
                        width: .48rem;
                        height: .48rem;
                    }
                }
                .right{
                    width: calc(100% - .96rem);
                    h5{
                        font-size: .14rem;
                        font-weight: bold;
                        color: #333333;
                        margin-bottom: .17rem;
                    }
                    p{
                        font-size: .14rem;
                        font-weight: 400;
                        line-height: .24rem;
                        color: #666666;
                        span{
                            width: calc(100% - .24rem);
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                        label{
                            width: .24rem;
                            height: .24rem;
                        }
                    }
                }
                &:last-of-type{
                    border-bottom: none;
                }
            }
        }
    }
    .lift_sys_tab{
        position: absolute;
        left: 0;
        right: 0;
        bottom: .2rem;
        .sys_btn{
            font-size: .14rem;
            font-weight: 400;
            color: #FFFFFF;
            height: .32rem;
            padding: 0 .18rem 0 .12rem;
            border-radius: .06rem;
            margin: 0 .05rem;
            i{
                width: .24rem;
                height: .24rem;
                margin-right: .02rem;
            }
        }
    }
    .war_list{
        .war_item{
            border-bottom: 1px solid #DDDDDD;
            .title{
                margin-top: .17rem;
                font-size: .14rem;
                line-height: .28rem;
                label{
                    font-weight: bold;
                    color: #333333;
                }
                em{
                    font-weight: 400;
                    color: #0278F1;
                }
            }
            .main_are{
                padding: .15rem 0 .18rem;
                p{
                    font-size: .14rem;
                    font-weight: 500;
                    color: #666666;
                    label{
                        width: .16rem;
                        height: .16rem;
                        margin-right: .08rem;
                    }
                }
            }
            &:last-of-type{
                border-bottom: none;
            }
        }
    }
}
.carve_item{
    height: 1.3rem;
    .carve_box{
        height: .66rem;
        padding: 0 .21rem;
        white-space: nowrap;
        .progr_item{
            width: 100%;
            label{
                font-size: .14rem;
                font-weight: 400;
                color: #333333;
            }
            .progr_box{
                width: 1.88rem;
                margin: 0 .09rem;
                /deep/.el-progress{
                    width: 100%;
                    .el-progress__text{
                        font-size: .14rem !important;
                        font-family: "Arial";
                    }
                    &.b_gree{
                        .el-progress-bar__inner{
                            background-color: #36D760;
                        }
                    }
                    &.b_ye{
                        .el-progress-bar__inner{
                            background-color: #FFC000;
                        }
                    }
                }
            }
        }
    }
    .carve_box_right{
        height: .66rem;
        padding: 0 .21rem;
        label{
            width: .48rem;
            height: .48rem;
        }
        .carve_main{
            padding-left: .22rem;
            p{
                font-size: .14rem;
                font-weight: 400;
                color: #999999;
                line-height: .28rem;
            }
            h5{
                font-size: .18rem;
                font-weight: 400;
                color: #333333;
                line-height: .28rem;
            }
        }
    }
}
.map_are{
    width: 100%;
    height: 100%;
    /deep/img{
        max-width:none;
        max-height:none;
        width: 100%;
    }
}
.sample {
    width: 250px;
    min-height: 100px;
    line-height: 24px;
    // border-radius: 3px;
    position: absolute;
    border-left: 1px solid #0278F1;
}
.sample .infoBox{
    background-color: #ffffff;
    border-radius: 3px;
    border: 1px solid #0278F1;
    margin-bottom: 30px;
}
.sample .infoBox label{
    width: 16px;
    height: 16px;
    margin: 0 5px;
}
.sample .infoBox .txt{
    padding-left: 30px;
}
// .map_are{
//     width: 100%;
//     height: 100%;
//     font-size: .14rem;
//     /deep/img{
//         max-width:none;
//         max-height:none;
//         width: 100%;
//     }
//     /deep/.infoBoxContent{
//         width:100%;
//         min-height:.59rem;
//         background-color:#ffffff;
//         border: 1px solid #0278F1;
//         border-radius: 3px;
//         label{
//             width: .3rem;height: .3rem;
//             }
//         .text{
//             padding-left:.1rem;
//             p{
//                 font-size: .14rem;font-weight: 400;line-height: .24rem;color: #333333;
//             }
//         }
//     }
// }
// /deep/.BMap_shadow{
//     display: none;
// }
.empty_are{
    height: 10vh;
    width: 100%;
    color: #999999;
}
</style>