import { render, staticRenderFns } from "./audiomode.vue?vue&type=template&id=4e2a2f8f&scoped=true"
import script from "./audiomode.vue?vue&type=script&lang=js"
export * from "./audiomode.vue?vue&type=script&lang=js"
import style0 from "./audiomode.vue?vue&type=style&index=0&id=4e2a2f8f&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e2a2f8f",
  null
  
)

export default component.exports