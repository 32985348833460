<template>
    <div class="video_container"
        v-loading="loading">
        <div class="error_tip f-box" v-if="showError">
            <p>当前终端不在线或拉取视频失败</p>
        </div>
        <videoPlayer class="vjs-custom-skin videoPlayer" :playsinline="true" width="100%" height="100%" :options="playerOptions" ref="videoPlayer" v-else id="video"></videoPlayer>
    </div>
</template>
<script>
import { getData} from '@/api/mc.js';
import 'video.js/dist/video-js.css'
import { videoPlayer } from 'vue-video-player'
import 'videojs-flash'
// import 'videojs-contrib-hls'
import SWF_URL from 'videojs-swf/dist/video-js.swf'; 
// import flvjs from "flv.js"
export default {
    props: ["videoItem"],
    components:{
        videoPlayer
    },
    data() {
        return {
            // 视频播放
            iccmTestInfo:'',
            playerOptions: {},
            loading:true,
            showError:false
        };
    },
    computed: {
        player() {
            return this.$refs.videoPlayer.player;
        }
    },
    mounted() {
        this.iccmTestInfo = JSON.parse(JSON.stringify(this.videoItem));
        console.log(this.videoItem)
        if(this.videoItem.video_type=='live'){
            this.getLiftMonitor()
        }else{
            this.setOtherVideo(this.videoItem)
        }
    },
    methods: {
        onPlayerPause() {
            if(!this.showError){
                this.$refs.videoPlayer.dispose(); //关闭弹窗视频暂停方法
            }
        },
        getLiftMonitor(){
            var main = this.iccmTestInfo;
            var temp = {
                face:'getMonitor',
                data:{
                    id:main.iccmId
                }
            }
            getData(temp).then(res=>{
                if(res.success){
                    this.getLiftMonitorUrl(main)
                }else{
                    this.loading = false;
                    this.showError = true
                }
            })
        },
        getLiftMonitorUrl(main){
            var temp = {
                face:'getMonitorUrl',
                data:{
                    iccmId:main.iccmId
                }
            }
            getData(temp).then(res=>{
                this.loading = false
                if(res.success){
                    var main = JSON.parse(JSON.stringify(this.iccmTestInfo)),url='';
                    if(res.data.isTalk){
                        main.telUrl += '/'+res.data.vid;
                        url = main.telUrl
                    }else{
                        main.recUrl += '/'+res.data.vid
                        url = main.recUrl
                    }
                    var tp = {
                        width: "210",
                        height: "134",
                        language: 'zh-CN',
                        techOrder: ['flash'],
                        muted: true,
                        autoplay: true,
                        controls: true,
                        loop: true,
                        sources: [
                            {
                                type: 'rtmp/flv',
                                src: url //rtmp流地址
                            }
                        ],
                        flash:{
                            swf:SWF_URL
                        }
                    }
                    console.log(url)
                    // this.DataTimes = new Date().getTime();
                    this.playerOptions = tp
                }else{
                    this.showError = true
                }
            })
        },
        setOtherVideo(main){
            this.loading = false
            var tp = {
                width: "210",
                height: "134",
                language: 'zh-CN',
                techOrder: ['flash'],
                muted: true,
                autoplay: true,
                controls: true,
                loop: false,
                sources: [
                    {
                        type: 'rtmp/mp4',//video/flv
                        src: main.local //rtmp流地址
                    }
                ],
                flash:{
                    swf:SWF_URL
                }
            }
            console.log(tp)
            this.playerOptions = tp
        }
    }
};
</script>
<style lang="less" scoped>
.video_container{
    width: 100%;
    height: 100%;
    position: relative;
    .videoPlayer{
        width: 100%;
        height: 100%;
        /deep/.video-js {
            width: 100% !important;
            height: 100% !important;
            .vjs-big-play-button {
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
    .error_tip{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}
</style>